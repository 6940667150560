import React, { useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  NoDataWrapper,
  StyledTab,
  StyledTabs,
  TabText,
  TableWrapper,
  TabsLoaderWrapper,
} from './Style/SingleThreat.style';
import { Loader, TabPanel } from '../../components';
import { a11yProps } from '../../utils';
import {
  getDNSRecordAction,
  getSubDomainsAction,
  getWhoisAction,
} from '../../store/singleThreat/action';
import DNSRecordsTab from './ThreadsMoreDetails/DNSRecordsTab';
import SSLCertificateTab from './ThreadsMoreDetails/SSLCertificateTab';
import WhoisTab from './ThreadsMoreDetails/WhoisTab';
import { ThreatTypes } from '../../helpers/types';
import SubDomainsTab from './ThreadsMoreDetails/SubDomainsTab ';

const DomainInformation = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const [detailTabValue, setDetailTabValue] = useState(0);

  const dispatch = useDispatch();
  const { dnsRecord, sslCertificate, whois, subDomains } = useSelector(
    (state: any) => state.singleThreat,
  );
  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setDetailTabValue(newValue);
      newValue === 1 &&
        dispatch(getWhoisAction(singleThreat?.threat_name || ''));
      newValue === 2 &&
        dispatch(getDNSRecordAction(singleThreat?.threat_name || ''));
      newValue === 3 &&
        dispatch(getSubDomainsAction(singleThreat?.protected_asset_id || null));
    },
    [singleThreat],
  );

  const threatDetailLoader = () => {
    return (
      <TabsLoaderWrapper>
        <Loader size={25} />
      </TabsLoaderWrapper>
    );
  };

  const noRecordsFound = () => {
    return <NoDataWrapper>No Data Found</NoDataWrapper>;
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Domain Information
      </Typography>
      <TableWrapper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs value={detailTabValue} onChange={handleChangeTabs}>
            <StyledTab label="SSL Certificate" {...a11yProps(0)} sx={TabText} />
            <StyledTab label="Whois" {...a11yProps(1)} sx={TabText} />
            <StyledTab label="Records" {...a11yProps(2)} sx={TabText} />
            <StyledTab label="Subdomains" {...a11yProps(3)} sx={TabText} />
          </StyledTabs>
        </Box>
        <TabPanel value={detailTabValue} index={0} style={{ padding: '16px' }}>
          {sslCertificate?.loading ? (
            threatDetailLoader()
          ) : sslCertificate?.data?.length === 0 ? (
            <NoDataWrapper>SSL Certificate Not listed</NoDataWrapper>
          ) : (
            <SSLCertificateTab
              sslCertificate={sslCertificate?.data}
              singleThreat={singleThreat}
            />
          )}
        </TabPanel>
        <TabPanel value={detailTabValue} index={1} style={{ padding: '16px' }}>
          {whois?.loading ? (
            threatDetailLoader()
          ) : whois?.data?.length === 0 ? (
            noRecordsFound()
          ) : (
            <WhoisTab whois={whois?.data} />
          )}
        </TabPanel>
        <TabPanel value={detailTabValue} index={2} style={{ padding: '16px' }}>
          {dnsRecord?.loading ? (
            threatDetailLoader()
          ) : (
            <DNSRecordsTab dnsRecord={dnsRecord?.data} />
          )}
        </TabPanel>
        <TabPanel value={detailTabValue} index={3} style={{ padding: '16px' }}>
          {subDomains?.loading ? (
            threatDetailLoader()
          ) : subDomains?.data?.length === 0 ? (
            noRecordsFound()
          ) : (
            <SubDomainsTab subDomains={subDomains?.data} />
          )}
        </TabPanel>
      </TableWrapper>
    </>
  );
};

export default DomainInformation;
