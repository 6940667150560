import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Login from './pages/Login/Login';
import SingleThreatDetail from './pages/ThreadsDetails/SingleThreatDetail';
import TakedownList from './pages/TakedownList/TakedownList';
import CompaniesList from './pages/CompaniesList/CompaniesList';
import CompanyDetails from './pages/CompanyDetails/CompanyDetails';
import ActiveDefense from './pages/ActiveDefense/ActiveDefense';
import FalsePositive from './pages/FalsePositive/FalsePositive';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Layout />} />
        <Route
          path="threats/:threatName/:domainName/:companyId"
          element={<SingleThreatDetail />}
        />
        <Route path="/takedown" element={<TakedownList />} />
        <Route path="/companies" element={<CompaniesList />} />
        <Route path="/companies/:id/view" element={<CompanyDetails />} />
        <Route path="/active-defense" element={<ActiveDefense />} />
        <Route path="/false-positive" element={<FalsePositive />} />
      </Routes>
    </Router>
  );
}

export default App;
