import React, { useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import { Button, DatePickers, Header, Input, Sidebar } from '../../components';
import { FilterWrapper } from '../CompaniesList/Style/CompaniesList.style';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import {
  LayoutContainer,
  MainContainer,
} from '../../components/Layout/Style/Layout.style';
import AddFalseLoadDomainModal from './AddFalseLoadDomainModal';
import FalsePositiveTable from './FalsePositiveTable';

const FalsePositive = () => {
  const [open, setOpen] = useState(true);
  const [openFalseLoadDomain, setOpenFalseLoadDomain] =
    useState<boolean>(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenFalseLoadDomain = () => {
    setOpenFalseLoadDomain(true);
  };

  const handleCloseFalseLoadDomain = () => {
    setOpenFalseLoadDomain(false);
  };

  return (
    <div>
      <Box sx={LayoutContainer}>
        <Header open={open} />
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
        <MainContainer>
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
            Active Defense List
          </Typography>
          <FilterWrapper>
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Input size="small" placeholder="search for name" />
              <Box sx={{ width: '240px' }}>
                <DatePickers
                  size="small"
                  // onChange={onChangeDate}
                />
              </Box>
            </Box>
            <Box>
              <Button variant="outlined" sx={{ mr: '12px' }}>
                Export
              </Button>
              <Button
                variant="contained"
                sx={{ background: '#0fd6c2' }}
                onClick={handleOpenFalseLoadDomain}>
                + Load Domain
              </Button>
            </Box>
          </FilterWrapper>
          <FalsePositiveTable />
          <PaginationStyle>
            <Pagination
              count={5}
              page={10}
              // onChange={handleChangeDomainPage}
              color="primary"
            />
          </PaginationStyle>
        </MainContainer>
      </Box>
      <AddFalseLoadDomainModal
        handleClose={handleCloseFalseLoadDomain}
        open={openFalseLoadDomain}
      />
    </div>
  );
};

export default FalsePositive;
