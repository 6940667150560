import { call, put } from 'redux-saga/effects';
import {
  CREATE_THREATCOMMENTS_FAILURE,
  CREATE_THREATCOMMENTS_SUCCESS,
  CREATE_THREATNOTES_FAILURE,
  CREATE_THREATNOTES_SUCCESS,
  DELETE_THREATCOMMENTS_FAILURE,
  DELETE_THREATCOMMENTS_SUCCESS,
  DELETE_THREATNOTE_FAILURE,
  DELETE_THREATNOTE_SUCCESS,
  GET_NOTES_VALUES_FAILURE,
  GET_NOTES_VALUES_SUCCESS,
  GET_THREATCOMMENTS_FAILURE,
  GET_THREATCOMMENTS_SUCCESS,
  GET_NOTES_ID_FAILURE,
  GET_NOTES_ID_SUCCESS,
  UPDATE_THREATCOMMENTS_FAILURE,
  UPDATE_THREATCOMMENTS_SUCCESS,
} from '../constant';
import {
  createThreatCommentsApi,
  createThreatNotesApi,
  deleteThreatCommentsApi,
  deleteThreatNoteApi,
  getNotesValuesApi,
  getThreatCommentsApi,
  getNoteIdApi,
  updateThreatCommentsApi,
} from '.';

export function* getNotesValuesSaga(action: any): any {
  try {
    const response = yield call(getNotesValuesApi);
    yield put({
      type: GET_NOTES_VALUES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NOTES_VALUES_FAILURE,
      payload: error.message,
    });
  }
}

export function* getNoteIdSaga(action: any): any {
  try {
    const response = yield call(getNoteIdApi, action.payload);
    yield put({
      type: GET_NOTES_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NOTES_ID_FAILURE,
      payload: error.message,
    });
  }
}

export function* createThreatNotesSaga(action: any): any {
  try {
    const response = yield call(createThreatNotesApi, action.payload);
    yield put({
      type: CREATE_THREATNOTES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: CREATE_THREATNOTES_FAILURE,
      payload: error.message,
    });
  }
}

export function* deleteThreatNoteSaga(action: any): any {
  try {
    const response = yield call(deleteThreatNoteApi, action.payload);
    yield put({
      type: DELETE_THREATNOTE_SUCCESS,
      payload: response.data,
    });
    const getThreatNotesRes = yield call(getNoteIdApi, {
      domain_id: action.payload?.domain_id,
      threat_id: action.payload?.threat_id,
      page: 1,
      size: 50,
    });
    yield put({
      type: GET_NOTES_ID_SUCCESS,
      payload: getThreatNotesRes.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_THREATNOTE_FAILURE,
      payload: error.message,
    });
  }
}

export function* getThreatCommentsSaga(action: any): any {
  try {
    const response = yield call(getThreatCommentsApi, action.payload);
    yield put({
      type: GET_THREATCOMMENTS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_THREATCOMMENTS_FAILURE,
      payload: error.message,
    });
  }
}

export function* createThreatCommentsSaga(action: any): any {
  try {
    const response = yield call(createThreatCommentsApi, action.payload);
    const getCommentsPayload = action.payload.items.map((item: any) => item);
    const getCommentsResponse = yield call(getThreatCommentsApi, {
      comments: getCommentsPayload[0].comments,
      domain_id: getCommentsPayload[0].domain_id,
      threat_id: getCommentsPayload[0].threat_id,
      is_public: getCommentsPayload[0].is_public,
    });
    yield put({
      type: CREATE_THREATCOMMENTS_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: GET_THREATCOMMENTS_SUCCESS,
      payload: getCommentsResponse.data,
    });
  } catch (error: any) {
    yield put({
      type: CREATE_THREATCOMMENTS_FAILURE,
      payload: error.message,
    });
  }
}

export function* updateThreatCommentsSaga(action: any): any {
  try {
    const response = yield call(updateThreatCommentsApi, {
      comments: action.payload?.comments,
      comment_id: action.payload?.comment_id,
      is_public: action.payload?.is_public,
    });
    const getCommentsResponse = yield call(getThreatCommentsApi, {
      domain_id: action.payload?.domain_id,
      threat_id: action.payload?.threat_id,
    });
    yield put({
      type: UPDATE_THREATCOMMENTS_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: GET_THREATCOMMENTS_SUCCESS,
      payload: getCommentsResponse.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_THREATCOMMENTS_FAILURE,
      payload: error.message,
    });
  }
}

export function* deleteThreatCommentsSaga(action: any): any {
  try {
    const response = yield call(deleteThreatCommentsApi, action.payload);
    yield put({
      type: DELETE_THREATCOMMENTS_SUCCESS,
      payload: response.data,
    });
    const getCommentsRes = yield call(getThreatCommentsApi, {
      domain_id: action.payload.domain_id,
      threat_id: action.payload.threat_id,
    });
    yield put({
      type: GET_THREATCOMMENTS_SUCCESS,
      payload: getCommentsRes.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_THREATCOMMENTS_FAILURE,
      payload: error.message,
    });
  }
}
