import { call, put } from 'redux-saga/effects';
import {
  GET_DNSRECORD_FAILURE,
  GET_DNSRECORD_SUCCESS,
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_SSLCERTIFICATE_FAILURE,
  GET_SSLCERTIFICATE_SUCCESS,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
  GET_WHOIS_FAILURE,
  GET_WHOIS_SUCCESS,
} from '../constant';
import {
  getSSLCertificateApi,
  getDNSRecordApi,
  getWhoisApi,
  getHistoryStatuesApi,
  getSubDomainsApi,
} from '.';

export function* getDNSRecordSaga(action: any): any {
  try {
    const response = yield call(getDNSRecordApi, action.payload);
    yield put({
      type: GET_DNSRECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_DNSRECORD_FAILURE,
      payload: error.message,
    });
  }
}

export function* getSSLCertificateSaga(action: any): any {
  try {
    const response = yield call(getSSLCertificateApi, action.payload);
    yield put({
      type: GET_SSLCERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SSLCERTIFICATE_FAILURE,
      payload: error.message,
    });
  }
}

export function* getWhoisSaga(action: any): any {
  try {
    const response = yield call(getWhoisApi, action.payload);
    yield put({
      type: GET_WHOIS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_WHOIS_FAILURE,
      payload: error.message,
    });
  }
}

export function* getSubDomainsSaga(action: any): any {
  try {
    const response = yield call(getSubDomainsApi, action.payload);
    yield put({
      type: GET_SUBDOMAINS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SUBDOMAINS_FAILURE,
      payload: error.message,
    });
  }
}

export function* getHistoryStatuesSaga(action: any): any {
  try {
    const response = yield call(getHistoryStatuesApi, action.payload);
    yield put({
      type: GET_HISTORY_STATUSES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_HISTORY_STATUSES_FAILURE,
      payload: error.message,
    });
  }
}
