import React from 'react';
import { Box, styled } from '@mui/material';
import { WhoisProps } from '../../../helpers/types';

const LabelInfoWrapper = styled(Box)({
  display: 'flex',
  color: 'black',
});

const LinesStyle = {
  margin: '6px 0',
};

const LabelInfo = styled(Box)({
  color: 'gray',
  width: '150px',
});

const RawWhoisData = styled(Box)({
  backgroundColor: '#e6e6e6',
  color: 'black',
  marginTop: '8px',
  padding: '12px',
});

const WhoisTab = ({ whois }: WhoisProps) => {
  return (
    <>
      <LabelInfoWrapper>
        <LabelInfo>Domain: </LabelInfo>
        <span>{whois?.Domain}</span>
      </LabelInfoWrapper>
      <hr style={LinesStyle} />
      <LabelInfoWrapper>
        <LabelInfo>Registrar: </LabelInfo>
        <span>{whois?.Registrar}</span>
      </LabelInfoWrapper>
      <hr style={LinesStyle} />
      <LabelInfoWrapper>
        <LabelInfo>Registered On: </LabelInfo>
        <span>{whois?.Registration}</span>
      </LabelInfoWrapper>
      <Box sx={{ m: '16px 8px' }}>
        <span style={{ color: 'gray' }}>Raw Whois Data:</span>
        <RawWhoisData>
          {whois.Text.split('\n').map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </RawWhoisData>
      </Box>
    </>
  );
};

export default WhoisTab;
