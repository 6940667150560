import { Box, styled } from '@mui/material';

export const MainContainer = styled(Box)(() => ({
  flexGrow: 1,
  padding: '24px',
  marginTop: '60px',
  minHeight: '95vh',
}));

export const LayoutContainer = {
  display: 'flex',
};

export const LoaderContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90vh',
  width: '100%',
}));
