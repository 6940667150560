import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GradientButton, Header, Loader, Sidebar } from '../../components';
import {
  LayoutContainer,
  LoaderContainer,
  MainContainer,
} from '../../components/Layout/Style/Layout.style';
import Avtar from '../../assets/Images/NoCompanyLogo.png';
import CompanyDetailsTabs from './CompanyDetailsTabs';
import {
  getCompanyAction,
  getCompanyDetailAction,
  getProtectedDomainsAction,
} from '../../store/company/action';
import ContactsIcon from '../../assets/Images/ContactsIcon.svg';
import AddressIcon from '../../assets/Images/AddressIcon.svg';
import EmailIcon from '../../assets/Images/EmailIcon.svg';
import {
  ServiceLabel,
  CompanyNameWrapper,
  LogoStyle,
  PaperStyle,
  ThreatNumberStyle,
  ThreatNumberWrapper,
  OverviewLabel,
} from './Style/CompanyDetails.style';
import AddChildModal from './AddChildModal';

const CompanyDetails = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [openAddChildModal, setOpenAddChildModal] = useState<boolean>(false);
  const [domainCurrentPage, setDomainCurrentPage] = useState<number>(1);
  const [searchDomainName, setSearchDomainName] = useState<string>('');

  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const { getCompanyDetail, getProtectedDomains } = useSelector(
    (state: any) => state.company,
  );
  const companyDetail = getCompanyDetail.data;
  const domainCount = Math.ceil(getProtectedDomains?.data?.Total / 10);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetailAction({ id: companyId }));
    }
  }, [companyId]);

  useEffect(() => {
    dispatch(
      getProtectedDomainsAction({
        c: companyId,
        ipp: 10,
        pn: domainCurrentPage,
        h: searchDomainName,
      }),
    );
  }, [domainCurrentPage, searchDomainName]);

  const handleOpenAddChild = () => {
    setOpenAddChildModal(true);
    dispatch(getCompanyAction());
  };

  const handleCloseAddChild = () => {
    setOpenAddChildModal(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangeDomainPage = (event: any, value: number) => {
    setDomainCurrentPage(value);
  };

  const handleChangeDomainName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchDomainName(e.target.value);
  };

  return (
    <>
      <Box sx={LayoutContainer}>
        <Header open={open} />
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
        {getCompanyDetail.loading ? (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        ) : (
          <MainContainer style={{ backgroundColor: '#f6f7f87f' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
              Companies Overview
            </Typography>
            <Paper elevation={1} sx={PaperStyle}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <img
                    src={companyDetail.Logo ? companyDetail.Logo : Avtar}
                    alt="logo"
                    style={LogoStyle}
                  />
                  <Box>
                    <CompanyNameWrapper>
                      <Typography variant="h6" fontWeight={600}>
                        {companyDetail?.Name}
                      </Typography>
                      {companyDetail?.Services &&
                      companyDetail.Services?.length > 0 ? (
                        <ServiceLabel variant="body2">
                          {companyDetail.Services[0]?.Name}
                        </ServiceLabel>
                      ) : (
                        <ServiceLabel variant="body2">NAN</ServiceLabel>
                      )}
                    </CompanyNameWrapper>
                    <div style={{ display: 'flex' }}>
                      <OverviewLabel variant="body2">
                        <img src={ContactsIcon} alt="" />
                        {companyDetail.Users?.length} {'Contacts'}
                      </OverviewLabel>
                      <OverviewLabel variant="body2">
                        <img src={EmailIcon} alt="" />
                        {companyDetail?.Address || 'No Addresss'}
                      </OverviewLabel>
                      <OverviewLabel variant="body2">
                        <img src={AddressIcon} alt="" />
                        {companyDetail?.Email || 'No Email'}
                      </OverviewLabel>
                    </div>
                    <ThreatNumberWrapper>
                      <ThreatNumberStyle>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          ?
                        </Typography>
                        <Typography variant="body2">Threats</Typography>
                      </ThreatNumberStyle>
                      <ThreatNumberStyle>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {getProtectedDomains.data?.Total}
                        </Typography>
                        <Typography variant="body2">
                          Protected Domains
                        </Typography>
                      </ThreatNumberStyle>
                    </ThreatNumberWrapper>
                  </Box>
                </div>
                <div>
                  <Link to={`/dashboard`} target="_blank">
                    <GradientButton variant="contained" sx={{ mr: 2 }}>
                      Threats
                    </GradientButton>
                  </Link>
                  <GradientButton
                    variant="contained"
                    onClick={handleOpenAddChild}>
                    Add Child Entity
                  </GradientButton>
                </div>
              </Box>
            </Paper>
            <CompanyDetailsTabs
              handleChangeDomainPage={handleChangeDomainPage}
              domainCurrentPage={domainCurrentPage}
              domainCount={domainCount}
              searchDomainName={searchDomainName}
              handleChangeDomainName={handleChangeDomainName}
            />
          </MainContainer>
        )}
      </Box>
      <AddChildModal
        handleClose={handleCloseAddChild}
        open={openAddChildModal}
      />
    </>
  );
};

export default CompanyDetails;
