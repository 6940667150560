import { Box, styled } from '@mui/material';

export const CardContent = styled(Box)({
  padding: '8px 0',
  whiteSpace: 'nowrap',
});

export const CardWrapper = {
  border: '1px solid #e2e2e2',
  borderRadius: '10px',
  padding: '14px',
  position: 'relative',

  '&:hover': {
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    backgroundColor: '#c5c3c326',
  },
};

export const LoaderWrapper = {
  border: '1px solid #e2e2e2',
  borderRadius: '10px',
  padding: '14px',
  pointerEvents: 'none',
  opacity: 0.4,
};

export const CardTitle = {
  '& .MuiFormControlLabel-label': { fontWeight: 600, padding: '0 4px' },
  '& .MuiCheckbox-root': { paddingY: 0 },
};

export const StatusWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '6px',
});

export const CardActions = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
});
