import {
  Box,
  Dialog,
  styled,
  TableCell,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { StyledTabProps, StyledTabsProps } from '../../../helpers/types';
import { Button } from '../../../components';

export const ImageStyle = {
  minWidth: '100%',
  height: 300,
  cursor: 'pointer',
};

export const TabText = {
  fontWeight: 'bold',
};

export const StatusButtonWrapper = styled(Box)({
  marginTop: '8px',
});

export const StatusButton = {
  marginRight: '8px',
};

export const SummaryTable = styled(Box)({
  padding: '16px',
  marginTop: '8px',
  border: '1px solid #ebebeb',
  borderRadius: '4px',
});

export const TableWrapper = styled(Box)({
  marginTop: '8px',
  border: '1px solid #ebebeb',
  borderRadius: '4px',
});

export const ExternalToolListWrapper = styled(Box)({
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  gap: '4px',
  marginTop: '10px',
});

export const TabsLoaderWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  margin: '60px 0',
});

export const NoDataWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  margin: '60px 0',
});

export const PaginationStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  margin: '12px 0',
});

export const SingleThreatLoaderWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
});

export const NoteLabelWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '16px 25% 6px 0',
});

export const CheckboxStyle = {
  padding: '4px',
  '&.Mui-checked': {
    color: '#0fd6c2',
  },
};

export const FormControlLabelStyle = {
  padding: '0 6px',
  '& .MuiTypography-root': { fontSize: '14px' },
};

export const ScreenshotWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '24px',
});

export const CompleteTakedowntDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    height: 'auto',
    width: 530,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogContentText-root': {
    color: 'black',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
    marginTop: '20px',
    justifyContent: 'end',
    display: 'flex',
  },
}));

export const CusttomButton = styled(Button)(() => ({
  '&.MuiButtonBase-root': {
    backgroundColor: '#07e2ba',
    padding: '6px 16px',
    color: 'white',
    width: 'auto',
  },
}));

export const CancelButton = styled(Button)(() => ({
  '&.MuiButtonBase-root': {
    backgroundColor: '#291b67',
    padding: '6px 16px',
    color: 'white',
  },
}));

export const TakedownTable = styled(Box)({
  marginTop: '8px',
  border: '1px solid #ebebeb',
  borderRadius: '4px',
});

export const TableHeaderCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
}));

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',
    marginRight: '8%',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 0,
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  color: '#0fd6c2',
  '&.Mui-selected': {
    color: 'black',
  },
}));

export const ButtonsStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  margin: '10px 0 26px',
});

export const CreatedByLabel = styled(Box)({
  display: 'flex',
  gap: '24px',
  justifyContent: 'end',
  marginTop: '6px',
});

export const LinesStyle = {
  margin: '6px 0',
};

export const EditCommentsIconStyle = {
  height: 22,
  marginRight: '6px',
  marginLeft: '6px',
};

export const PrivateLabel = styled(Typography)({
  background: '#0fd6c2',
  borderRadius: '4px',
  color: 'white',
  height: 'fit-content',
  padding: '4px 10px',
  fontSize: 11,
});

export const CommentsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '-webkit-fill-available',
});

export const EditModeWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

export const UpdateButton = styled(Button)({
  width: '60px',
  padding: '4px',
  height: 'fit-content',
});

export const CommentsText = styled(Typography)({
  fontSize: 15,
  color: 'black',
  display: 'flex',
});
