import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import CloseIcon from '@mui/icons-material/Close';
import { GradientButton, Loader } from '../../../components';
import Avtar from '../../../assets/Images/Avtar.png';
import './PreviewPdfModal.css';
import { PreviewPdfModalType } from '../../../helpers/types';

const PreviewPdfDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    height: '90vh',
    minWidth: '60%',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PreviewPdfModal = ({
  openPreviewPdfModal,
  handleClose,
  singleThreat,
  imageData,
}: PreviewPdfModalType) => {
  const [loader, setLoader] = useState(false);

  const { dnsRecord, sslCertificate, whois } = useSelector(
    (state: any) => state.singleThreat,
  );

  const downloadPDF = () => {
    const capture: any = document.querySelector('.actual-receipt');
    setLoader(true);
    html2canvas(capture).then((canvas: any) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save('receipt.pdf');
    });
  };

  const filteredRecords = dnsRecord?.data?.filter(
    (item: any) =>
      item.RecordType === 'A' ||
      item.RecordType === 'MX' ||
      item.RecordType === 'TXT',
  );

  const recordTypeA = () => {
    return filteredRecords?.map(
      (item: any, index: number) =>
        item.RecordType === 'A' && <span key={index}>{item.Address}</span>,
    );
  };

  const recordTypeMX = () => {
    return filteredRecords?.map(
      (item: any, index: number) =>
        item.RecordType === 'MX' && <span key={index}>{item.Server}</span>,
    );
  };

  const recordTypeTXT = () => {
    return filteredRecords?.map(
      (item: any, index: number) =>
        item.RecordType === 'TXT' && <span key={index}>{item.Address}</span>,
    );
  };

  const ServersName = () => {
    return filteredRecords?.map((item: any, index: number) => (
      <span key={index}>{item.Server}</span>
    ));
  };
  return (
    <PreviewPdfDialog onClose={handleClose} open={openPreviewPdfModal}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 26,
          top: 10,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="wrapper">
          <div className="receipt-box">
            <GradientButton onClick={downloadPDF} sx={{ width: 140, mb: 1.5 }}>
              {loader ? (
                <span>
                  <Loader size={20} />
                </span>
              ) : (
                <span>Ganerate PDF</span>
              )}
            </GradientButton>
            <div className="actual-receipt">
              <table className="table table-border">
                <thead>
                  <tr>
                    <th className="middle" scope="col">
                      @Wheel2
                    </th>
                    <th className="middle top-info grow title" scope="col">
                      PreCrime Alert
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="middle bottom-info" scope="row" colSpan={2}>
                      Agatha Comments
                    </th>
                  </tr>
                  <tr>
                    <td className="middle">Hello </td>
                  </tr>
                  <tr>
                    <th className="middle bottom-info" scope="row">
                      PreCrime Alert
                    </th>
                    <th className="middle bottom-info" scope="row">
                      Protected Domain
                    </th>
                  </tr>
                  <tr>
                    <td className="middle middle-green">
                      {singleThreat?.threat_name}
                    </td>
                    <td className="middle middle-green">
                      {singleThreat?.protected_asset_name}
                    </td>
                  </tr>
                  <tr>
                    <th className="middle middle-info" scope="row" colSpan={2}>
                      Screenshot
                    </th>
                  </tr>
                  <tr>
                    <td className="middle" colSpan={2}>
                      <img
                        loading="lazy"
                        className="screenshot"
                        src={
                          imageData === undefined
                            ? Avtar
                            : `data:image/jpeg;base64,${imageData}`
                        }
                        alt="Domain Screenshot"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="middle bottom-info" colSpan={2}>
                      Active Defense Action Taken
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <p>
                        <span>&#62; Set as"Malicious" on Virustotal</span>
                      </p>
                      <p>
                        <span>
                          &#62; Included in Global Cyber Alliance Domain Trust
                        </span>
                      </p>
                      <p>
                        <span>&#62; Added to Quad9Protective DNS</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th className="middle bottom-info" colSpan={2}>
                      DNS Information
                    </th>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">Date Created</td>
                    <td className="middle bottom-info">Expiration</td>
                  </tr>
                  <tr>
                    <td className="middle">{whois.data.Registration}</td>
                    <td className="middle">{whois.data.Expiration}</td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">Last Update</td>
                    <td className="middle bottom-info">Registrar</td>
                  </tr>
                  <tr>
                    <td className="middle">{whois.data.LastModified}</td>
                    <td className="middle">{whois.data.Registrar}</td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">A</td>
                    <td className="middle bottom-info">Name Servers</td>
                  </tr>
                  <tr>
                    <td className="middle">{recordTypeA()}</td>
                    <td className="middle label-style">{ServersName()}</td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">MX</td>
                    <td className="middle bottom-info">TXT</td>
                  </tr>
                  <tr>
                    <td className="middle label-style">{recordTypeMX()}</td>
                    <td className="middle label-style">{recordTypeTXT()}</td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info" colSpan={2}>
                      Certificate Information
                    </td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">Issuer</td>
                    <td className="middle bottom-info">for Domain</td>
                  </tr>
                  <tr>
                    <td className="middle">{sslCertificate?.data?.Issuer}</td>
                    <td className="middle">{sslCertificate?.data?.Subject}</td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info">valid From</td>
                    <td className="middle bottom-info">Valid To</td>
                  </tr>
                  <tr>
                    <td className="middle">{sslCertificate?.data?.NotAfter}</td>
                    <td className="middle">
                      {sslCertificate?.data?.NotBefore}
                    </td>
                  </tr>
                  <tr>
                    <td className="middle bottom-info" colSpan={2}>
                      Subdomains
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DialogContent>
    </PreviewPdfDialog>
  );
};

export default PreviewPdfModal;
