import React from 'react';
import { Box, styled } from '@mui/material';
import { SubDomainsTabProps } from '../../../helpers/types';

const LabelInfoWrapper = styled(Box)({
  display: 'flex',
  color: 'black',
});

const LinesStyle = {
  margin: '6px 0',
};

const SubDomainsTab = ({ subDomains }: SubDomainsTabProps) => {
  return (
    <Box sx={{ mt: 1, height: '300px', overflowY: 'auto' }}>
      {subDomains.map((item, index) => (
        <div key={index}>
          <LabelInfoWrapper>
            <span>{item?.Name}</span>
          </LabelInfoWrapper>
          <hr style={LinesStyle} />
        </div>
      ))}
    </Box>
  );
};

export default SubDomainsTab;
