import {
  DeleteCommentsType,
  DeleteNoteType,
  GetThreatNotesType,
  getCommentsType,
} from '../../helpers/types';
import {
  CREATE_THREATCOMMENTS_BEGIN,
  CREATE_THREATNOTES_BEGIN,
  DELETE_THREATNOTE_BEGIN,
  GET_THREATCOMMENTS_BEGIN,
  GET_NOTES_VALUES_BEGIN,
  UPDATE_THREATCOMMENTS_BEGIN,
  GET_NOTES_ID_BEGIN,
  DELETE_THREATCOMMENTS_BEGIN,
} from '../constant';

export const getNotesValuesAction = (payload: any) => ({
  type: GET_NOTES_VALUES_BEGIN,
  payload,
});

export const getNotesIdAction = (payload: GetThreatNotesType) => ({
  type: GET_NOTES_ID_BEGIN,
  payload,
});

export const createThreatNotesAction = (payload: any) => ({
  type: CREATE_THREATNOTES_BEGIN,
  payload,
});

export const deleteNoteAction = (payload: DeleteNoteType) => ({
  type: DELETE_THREATNOTE_BEGIN,
  payload,
});

export const getThreatCommentsAction = (payload: getCommentsType) => ({
  type: GET_THREATCOMMENTS_BEGIN,
  payload,
});

export const createThreatCommentsAction = (payload: any) => ({
  type: CREATE_THREATCOMMENTS_BEGIN,
  payload,
});

export const updateCommentsAction = (payload: any) => ({
  type: UPDATE_THREATCOMMENTS_BEGIN,
  payload,
});

export const deleteCommentsAction = (payload: DeleteCommentsType) => ({
  type: DELETE_THREATCOMMENTS_BEGIN,
  payload,
});
