import { styled, Box } from '@mui/material';
import { DNSRecordProps } from '../../../helpers/types';

const LabelInfoWrapper = styled(Box)({
  display: 'flex',
  color: 'black',
});

const LabelInfo = styled(Box)({
  color: 'gray',
  minWidth: '150px',
});

const LinesStyle = {
  margin: '6px 0',
};

const NoDataWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  margin: '60px 0',
});

const DNSRecordsTab = ({ dnsRecord }: DNSRecordProps) => {
  const filteredRecords = dnsRecord?.filter(
    (item: any) =>
      item.RecordType === 'A' ||
      item.RecordType === 'AAAA' ||
      item.RecordType === 'MX',
  );

  return (
    <>
      {filteredRecords && filteredRecords.length > 0 ? (
        filteredRecords.map((item: any, index: number) => (
          <div key={index}>
            <LabelInfoWrapper>
              <LabelInfo>{item?.RecordType}</LabelInfo>
              {item.RecordType === 'MX' ? (
                <span>{item.Server}</span>
              ) : (
                <span>{item.Address}</span>
              )}
            </LabelInfoWrapper>
            <hr style={LinesStyle} />
          </div>
        ))
      ) : (
        <NoDataWrapper>No Data Found</NoDataWrapper>
      )}
    </>
  );
};

export default DNSRecordsTab;
