import React from 'react';
import { Box, styled } from '@mui/material';
import { SSlCertificateProps } from '../../../helpers/types';

const LabelInfoWrapper = styled(Box)({
  display: 'flex',
  color: 'black',
});

const LabelInfo = styled(Box)({
  color: 'gray',
  minWidth: '150px',
});

const LinesStyle = {
  margin: '6px 0',
};

const SSLCertificateTab = ({
  sslCertificate,
  singleThreat,
}: SSlCertificateProps) => {
  const registeredOnDate = new Date(sslCertificate?.NotAfter).toLocaleString();
  const notBeforeDate = new Date(sslCertificate?.NotBefore).toLocaleString();
 
  return (
    <>
      <LabelInfoWrapper>
        <LabelInfo>Domain: </LabelInfo>
        <span>{singleThreat?.protected_asset_name}</span>
      </LabelInfoWrapper>
      <hr style={LinesStyle} />
      <LabelInfoWrapper>
        <LabelInfo>Registrar: </LabelInfo>
        <span>{sslCertificate?.Issuer}</span>
      </LabelInfoWrapper>
      <hr style={LinesStyle} />
      <LabelInfoWrapper>
        <LabelInfo>Registered On: </LabelInfo>
        <span>{registeredOnDate}</span>
      </LabelInfoWrapper>
      <hr style={LinesStyle} />
      <LabelInfoWrapper>
        <LabelInfo>Not After: </LabelInfo>
        <span>{notBeforeDate}</span>
      </LabelInfoWrapper>
    </>
  );
};

export default SSLCertificateTab;
