/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Box, Grid, IconButton } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  ConfirmationModal,
  ImageSkeleton,
  Loader,
} from '../../components';
import { ThreatTypes } from '../../helpers/types';
import {
  SingleThreatLoaderWrapper,
  StatusButtonWrapper,
  TableWrapper,
  StatusButton,
  ImageStyle,
  ScreenshotWrapper,
} from './Style/SingleThreat.style';
import { getSSLCertificateAction } from '../../store/singleThreat/action';
import Avtar from '../../assets/Images/Avtar.png';
import {
  changeStatusLabelAction,
  getThreatAction,
  getThreatActionDetail,
} from '../../store/threats/action';
import { getThreatScreenshot } from '../../store/threats';
import ScreenShotModal from '../../components/ScreenShotModal/ScreenShotModal';
import StatusHistory from './StatusHistory';
import SummaryDetail from './SummaryDetail';
import DomainInformation from './DomainInformation';
import ExternalToolList from './ExternalToolList';
import TakedownDetails from './TakedownDetails';
import NotesComments from './NotesComments';
import { EVENT_NAME, PAGE_NAME, trackAnalytic } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { getCompanyAction } from '../../store/company/action';

const SingleThreatDetail = () => {
  const [singleThreat, setSingleThreat] = useState<ThreatTypes | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [statusLabelThreat, setStatusLabelThreat] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openScreenShotModal, setOpenScreenShotModal] =
    useState<boolean>(false);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [singleThreatStatus, setSingleThreatStatus] = useState<boolean>(true);
  const [startDateTime, setStartDateTime] = useState<any>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [searchParams] = useSearchParams();

  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { threatName, domainName, companyId }: any = useParams();

  const { threatDetail, loading } = useSelector((state: any) => state.threats);
  const { getCompany } = useSelector((state: any) => state.company);

  useEffect(() => {
    if (searchParams) {
      const protected_id = searchParams.get('protected_id') || '';
      const threat_id = searchParams.get('threat_id') || '';
      dispatch(
        getThreatActionDetail({
          protected_id: protected_id,
          threat_id: threat_id,
        }),
      );
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    setSingleThreat(threatDetail);
    const companies = getCompany?.data?.find(
      (c: any) => c?.Id === threatDetail?.company_id,
    );
    setCompanyDetail(companies);
  }, [threatDetail]);

  useEffect(() => {
    dispatch(getCompanyAction());
  }, []);

  useEffect(() => {
    setStartDateTime(Date.now());
  }, []);

  useEffect(() => {
    if (singleThreat && singleThreat.threat_name) {
      dispatch(getSSLCertificateAction(singleThreat.threat_name));
    }
  }, [singleThreat?.threat_name]);

  const fetchThreatScreenshot = async (threatName: string | undefined) => {
    try {
      setImageLoader(true);
      const res = await getThreatScreenshot(threatName);
      setImageData(res.data.Image);
    } catch (error) {
      console.error('Error fetching threat screenshot', error);
      setImageData(null);
    } finally {
      setImageLoader(false);
    }
  };

  useEffect(() => {
    if (singleThreat?.threat_name) {
      fetchThreatScreenshot(singleThreat?.threat_name);
    }
  }, [singleThreat?.threat_name]);

  const handleSyncIconClick = async () => {
    if (singleThreat?.threat_name) {
      fetchThreatScreenshot(singleThreat?.threat_name);
    }
  };

  const handleOpenSingleModal = (value: string) => {
    setStatusLabelThreat(value);
    setOpenModal(true);
  };

  const timeSpentOnPage = () => {
    const endTime = Date.now();
    const timeDiff = endTime - startDateTime;
    const timeSpentInSeconds = timeDiff / 1000;

    const analyticData = {
      domain: {
        id: singleThreat?.protected_asset_id,
        name: singleThreat?.protected_asset_name,
      },
      threat: {
        id: singleThreat?.threat_id,
        name: singleThreat?.threat_name,
      },
      user: { email: user?.email, name: user?.name },
      currentStatus: singleThreat?.identification_status.value,
      updatedStatus: statusLabelThreat,
      pageName: PAGE_NAME.SINGLE_THREAT_PAGE,
    };

    trackAnalytic(EVENT_NAME.TIME_TO_CONFIRM_MALCIOUSNESS, {
      ...analyticData,
      timeSpentOnPage: timeSpentInSeconds,
    });
  };

  const updateStatusDetailScreen = () => {
    if (singleThreat) {
      const data = {
        companyId: singleThreat?.company_id,
        domain: singleThreat?.protected_asset_name,
        domainId: singleThreat?.protected_asset_id,
        threat: singleThreat?.threat_name,
        threatId: singleThreat?.threat_id,
        status: statusLabelThreat,
        priority: '90',
        process: 'w',
        userId: user?.sub,
        singleThreatStatus,
        currentStatus: singleThreat?.identification_status?.value,
      };
      setOpenModal(false);
      const analytic = {
        pageName: PAGE_NAME.SINGLE_THREAT_PAGE,
        user: { email: user?.email, name: user?.name },
        isBulkUpdate: false,
      };
      dispatch(setAnalyticObject(analytic));
      timeSpentOnPage();
      dispatch(changeStatusLabelAction(data));
    }
  };

  const handleCopyThreatName = () => {
    navigator.clipboard.writeText(singleThreat?.threat_name || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleCloseScreenShot = () => {
    setOpenScreenShotModal(false);
  };

  if (loading) {
    return (
      <SingleThreatLoaderWrapper>
        <Loader size={30} />
      </SingleThreatLoaderWrapper>
    );
  }

  const detectionDate = new Date(threatDetail?.detection_date || '');
  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = detectionDate.toLocaleString('en-US', options);

  return (
    <Box sx={{ m: '30px' }}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Box>
            <Box sx={{ display: 'flex', gap: '18px' }}>
              <Typography variant="h5" fontWeight="bold">
                {threatDetail?.threat_name}
              </Typography>
              <IconButton
                onClick={handleCopyThreatName}
                size="small"
                sx={{ padding: '0' }}
                disabled={copied}>
                <ContentCopyIcon />
              </IconButton>
            </Box>

            <Typography variant="h6" sx={{ lineHeight: 1.1 }}>
              {threatDetail?.protected_asset_name} - {companyDetail?.Name}
            </Typography>

            <Typography variant="subtitle1" sx={{ lineHeight: 2 }}>
              <b>Detection:</b> {`On ${formattedDate}`} <br />
            </Typography>
            <StatusButtonWrapper>
              <Link
                to={`https://wheel.bfore.ai/threats/${singleThreat?.threat_name}/${singleThreat?.protected_asset_name}/${singleThreat?.company_id}`}
                target="_blank">
                <Button
                  variant="contained"
                  startIcon={<CheckCircleIcon />}
                  disabled={
                    singleThreat?.identification_status?.value === 'confirmed'
                      ? true
                      : false
                  }
                  sx={{ ...StatusButton, background: '#50CD89' }}>
                  Confirm
                </Button>
              </Link>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenSingleModal('discarded');
                }}
                startIcon={<CancelIcon />}
                disabled={
                  singleThreat?.identification_status?.value === 'discarded'
                    ? true
                    : false
                }
                sx={{ ...StatusButton, backgroundColor: '#131628' }}>
                Discard
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenSingleModal('trademark');
                }}
                startIcon={<OfflinePinIcon />}
                disabled={
                  singleThreat?.identification_status?.value === 'trademark'
                    ? true
                    : false
                }
                sx={{ ...StatusButton, backgroundColor: '#7239EA' }}>
                TradeMark
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenSingleModal('owned');
                }}
                startIcon={<PlaylistAddCircleIcon />}
                disabled={
                  singleThreat?.identification_status?.value === 'owned'
                    ? true
                    : false
                }
                sx={{ ...StatusButton, backgroundColor: '#25badb' }}>
                Owned
              </Button>
            </StatusButtonWrapper>
          </Box>
          <Box sx={{ my: 2 }}>
            <SummaryDetail singleThreat={singleThreat} />
          </Box>
          {singleThreat?.identification_status?.value === 'confirmed' && (
            <Box sx={{ my: 2 }}>
              <TakedownDetails singleThreat={singleThreat} />
            </Box>
          )}
          <Box sx={{ my: 2 }}>
            <DomainInformation singleThreat={singleThreat} />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <ExternalToolList singleThreat={singleThreat} imageData={imageData} />
          <ScreenshotWrapper>
            <Typography variant="h6" sx={{ color: 'black' }}>
              Screenshot
            </Typography>
            <SyncIcon
              onClick={handleSyncIconClick}
              sx={{ cursor: 'pointer' }}
            />
          </ScreenshotWrapper>
          <TableWrapper>
            {imageLoader ? (
              <ImageSkeleton height={300} />
            ) : (
              <Avatar
                variant={'square'}
                src={
                  imageData === undefined
                    ? Avtar
                    : `data:image/jpeg;base64,${imageData}`
                }
                onClick={() => setOpenScreenShotModal(true)}
                sx={ImageStyle}
              />
            )}
          </TableWrapper>
          <StatusHistory singleThreat={singleThreat} />
          <NotesComments singleThreat={singleThreat} />
        </Grid>
      </Grid>
      <ScreenShotModal
        imageData={imageData}
        handleCloseScreenShot={handleCloseScreenShot}
        openScreenShotModal={openScreenShotModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdate={() => updateStatusDetailScreen()}
      />
    </Box>
  );
};

export default SingleThreatDetail;
