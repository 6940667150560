import {
  GET_HISTORY_STATUSES_BEGIN,
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_SUBDOMAINS_BEGIN,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
} from './../constant';
import {
  GET_DNSRECORD_BEGIN,
  GET_DNSRECORD_FAILURE,
  GET_DNSRECORD_SUCCESS,
  GET_SSLCERTIFICATE_BEGIN,
  GET_SSLCERTIFICATE_FAILURE,
  GET_SSLCERTIFICATE_SUCCESS,
  GET_WHOIS_BEGIN,
  GET_WHOIS_FAILURE,
  GET_WHOIS_SUCCESS,
} from '../constant';

const initialState = {
  dnsRecord: {
    loading: false,
    data: [],
    error: null,
  },
  sslCertificate: {
    loading: false,
    data: [],
    error: null,
  },
  whois: {
    loading: false,
    data: [],
    error: null,
  },
  subDomains: {
    loading: false,
    data: [],
    error: null,
  },
  historyStatuses: {
    loading: false,
    data: [],
    error: null,
  },
};

export const singleThreatReducer = (
  state = initialState,
  action: { type: string; payload: string },
) => {
  switch (action.type) {
    case GET_DNSRECORD_BEGIN:
      return {
        ...state,
        dnsRecord: {
          ...state.dnsRecord,
          loading: true,
        },
      };
    case GET_DNSRECORD_SUCCESS:
      return {
        ...state,
        dnsRecord: {
          ...state.dnsRecord,
          loading: false,
          data: action.payload,
        },
      };
    case GET_DNSRECORD_FAILURE:
      return {
        ...state,
        dnsRecord: {
          ...state.dnsRecord,
          loading: false,
          error: action.payload,
        },
      };
    case GET_SSLCERTIFICATE_BEGIN:
      return {
        ...state,
        sslCertificate: {
          ...state.sslCertificate,
          loading: true,
        },
      };
    case GET_SSLCERTIFICATE_SUCCESS:
      return {
        ...state,
        sslCertificate: {
          ...state.sslCertificate,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SSLCERTIFICATE_FAILURE:
      return {
        ...state,
        sslCertificate: {
          ...state.sslCertificate,
          loading: false,
          error: action.payload,
        },
      };
    case GET_WHOIS_BEGIN:
      return {
        ...state,
        whois: {
          ...state.whois,
          loading: true,
        },
      };
    case GET_WHOIS_SUCCESS:
      return {
        ...state,
        whois: {
          ...state.whois,
          loading: false,
          data: action.payload,
        },
      };
    case GET_WHOIS_FAILURE:
      return {
        ...state,
        whois: {
          ...state.whois,
          loading: false,
          error: action.payload,
        },
      };
    case GET_SUBDOMAINS_BEGIN:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: true,
        },
      };
    case GET_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SUBDOMAINS_FAILURE:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          error: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_BEGIN:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: true,
        },
      };
    case GET_HISTORY_STATUSES_SUCCESS:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          data: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_FAILURE:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
