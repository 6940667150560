import {
  CREATE_THREATCOMMENTS_BEGIN,
  CREATE_THREATCOMMENTS_FAILURE,
  CREATE_THREATCOMMENTS_SUCCESS,
  CREATE_THREATNOTES_BEGIN,
  CREATE_THREATNOTES_FAILURE,
  CREATE_THREATNOTES_SUCCESS,
  DELETE_THREATNOTE_BEGIN,
  DELETE_THREATNOTE_FAILURE,
  DELETE_THREATNOTE_SUCCESS,
  GET_THREATCOMMENTS_BEGIN,
  GET_THREATCOMMENTS_FAILURE,
  GET_THREATCOMMENTS_SUCCESS,
  GET_NOTES_VALUES_BEGIN,
  GET_NOTES_VALUES_FAILURE,
  GET_NOTES_VALUES_SUCCESS,
  UPDATE_THREATCOMMENTS_BEGIN,
  UPDATE_THREATCOMMENTS_FAILURE,
  UPDATE_THREATCOMMENTS_SUCCESS,
  GET_NOTES_ID_BEGIN,
  GET_NOTES_ID_SUCCESS,
  GET_NOTES_ID_FAILURE,
  DELETE_THREATCOMMENTS_BEGIN,
  DELETE_THREATCOMMENTS_FAILURE,
  DELETE_THREATCOMMENTS_SUCCESS,
} from '../constant';

const initialState = {
  getNotesValues: {
    loading: false,
    data: [],
    error: null,
  },
  getNoteId: {
    loading: false,
    data: [],
    error: null,
  },
  createNotes: {
    loading: false,
    data: [],
    error: null,
  },
  deleteNote: {
    loading: false,
    data: [],
    error: null,
  },
  getComments: {
    loading: false,
    data: [],
    error: null,
  },
  createComments: {
    loading: false,
    data: [],
    error: null,
  },
  updateComments: {
    loading: false,
    data: [],
    error: null,
  },
  deleteComments: {
    loading: false,
    data: [],
    error: null,
  },
};

export const noteCommentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NOTES_VALUES_BEGIN:
      return {
        ...state,
        getNotesValues: { ...state.getNotesValues, loading: true },
      };
    case GET_NOTES_VALUES_SUCCESS:
      return {
        ...state,
        getNotesValues: {
          ...state.getNotesValues,
          loading: false,
          data: action.payload,
        },
      };
    case GET_NOTES_VALUES_FAILURE:
      return {
        ...state,
        getNotesValues: {
          ...state.getNotesValues,
          loading: false,
          error: action.payload,
        },
      };
    case GET_NOTES_ID_BEGIN:
      return {
        ...state,
        getNoteId: { ...state.getNoteId, loading: true },
      };
    case GET_NOTES_ID_SUCCESS:
      return {
        ...state,
        getNoteId: {
          ...state.getNoteId,
          loading: false,
          data: action.payload,
        },
      };
    case GET_NOTES_ID_FAILURE:
      return {
        ...state,
        getNoteId: {
          ...state.getNoteId,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_THREATNOTES_BEGIN:
      return {
        ...state,
        createNotes: {
          ...state.createNotes,
          loading: true,
        },
      };
    case CREATE_THREATNOTES_SUCCESS:
      return {
        ...state,
        createNotes: {
          ...state.createNotes,
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_THREATNOTES_FAILURE:
      return {
        ...state,
        createNotes: {
          ...state.createNotes,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_THREATNOTE_BEGIN:
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          loading: true,
        },
      };
    case DELETE_THREATNOTE_SUCCESS:
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          loading: false,
          data: action.payload,
        },
      };
    case DELETE_THREATNOTE_FAILURE:
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          loading: false,
          error: action.payload,
        },
      };
    case GET_THREATCOMMENTS_BEGIN:
      return {
        ...state,
        getComments: {
          ...state.getComments,
          loading: true,
        },
      };
    case GET_THREATCOMMENTS_SUCCESS:
      return {
        ...state,
        getComments: {
          ...state.getComments,
          loading: false,
          data: action.payload,
        },
      };
    case GET_THREATCOMMENTS_FAILURE:
      return {
        ...state,
        getComments: {
          ...state.getComments,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_THREATCOMMENTS_BEGIN:
      return {
        ...state,
        createComments: {
          ...state.createComments,
          loading: true,
        },
      };
    case CREATE_THREATCOMMENTS_SUCCESS:
      return {
        ...state,
        createComments: {
          ...state.createComments,
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_THREATCOMMENTS_FAILURE:
      return {
        ...state,
        createComments: {
          ...state.createComments,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_THREATCOMMENTS_BEGIN:
      return {
        ...state,
        updateComments: {
          ...state.updateComments,
          loading: true,
        },
      };
    case UPDATE_THREATCOMMENTS_SUCCESS:
      return {
        ...state,
        updateComments: {
          ...state.updateComments,
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_THREATCOMMENTS_FAILURE:
      return {
        ...state,
        updateComments: {
          ...state.updateComments,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_THREATCOMMENTS_BEGIN:
      return {
        ...state,
        deleteComments: {
          ...state.deleteComments,
          loading: true,
        },
      };
    case DELETE_THREATCOMMENTS_SUCCESS:
      return {
        ...state,
        deleteComments: {
          ...state.deleteComments,
          loading: false,
          data: action.payload,
        },
      };
    case DELETE_THREATCOMMENTS_FAILURE:
      return {
        ...state,
        deleteComments: {
          ...state.deleteComments,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
