import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Switch,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditCommentsIcon from '../../assets/Images/EditCommentsIcon.svg';
import {
  ButtonsStyle,
  CheckboxStyle,
  CommentsText,
  CommentsWrapper,
  CreatedByLabel,
  EditCommentsIconStyle,
  EditModeWrapper,
  FormControlLabelStyle,
  LinesStyle,
  NoteLabelWrapper,
  PrivateLabel,
  UpdateButton,
} from './Style/SingleThreat.style';
import { Button, GradientButton, Input, Loader } from '../../components';
import {
  createThreatCommentsAction,
  createThreatNotesAction,
  getNotesIdAction,
  getNotesValuesAction,
  getThreatCommentsAction,
  updateCommentsAction,
} from '../../store/noteComments/action';
import { ThreatTypes } from '../../helpers/types';

const NotesComments = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const [addThreatComments, setAddThreatComments] = useState<string>('');
  const [editMode, setEditMode] = useState<number | null>(null);
  const [editedComment, setEditedComment] = useState<string>('');
  const [editedIsPublic, setEditedIsPublic] = useState<boolean>(true);
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);

  const dispatch = useDispatch();
  const { getNotesValues, getComments, createNotes, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );
  const getCommentsList = getComments?.data?.items;

  useEffect(() => {
    if (singleThreat?.threat_id) {
      dispatch(getNotesValuesAction({}));
      dispatch(
        getNotesIdAction({
          domain_id: singleThreat?.protected_asset_id,
          threat_id: singleThreat?.threat_id,
          page: 1,
          size: 50,
        }),
      );
      dispatch(
        getThreatCommentsAction({
          domain_id: singleThreat?.protected_asset_id,
          threat_id: singleThreat?.threat_id,
        }),
      );
    }
  }, [singleThreat?.threat_id]);

  useEffect(() => {
    if (getNoteId?.data?.items) {
      const noteIds = getNoteId.data.items.map((item: any) => item.note_id);
      setSelectedNoteIds(noteIds);
    }
  }, [getNoteId]);

  const handleChangeComments = (e: any) => {
    setAddThreatComments(e.target.value);
  };

  const handleAddComments = () => {
    dispatch(
      createThreatCommentsAction({
        items: [
          {
            comments: addThreatComments,
            domain_id: singleThreat?.protected_asset_id,
            threat_id: singleThreat?.threat_id,
            is_public: editedIsPublic,
          },
        ],
      }),
    );
    setAddThreatComments('');
  };

  const handleToggleEditMode = (index: number) => {
    if (editMode === index) {
      setEditMode(null);
    } else {
      setEditMode(index);
      setEditedComment(getCommentsList[index].comments);
      setEditedIsPublic(getCommentsList[index].is_public);
    }
  };

  const handleChangeEditedComment = (e: any) => {
    setEditedComment(e.target.value);
  };

  const handleUpdateComment = (id: number) => {
    dispatch(
      updateCommentsAction({
        comments: editedComment,
        comment_id: id,
        is_public: editedIsPublic,
        domain_id: singleThreat?.protected_asset_id,
        threat_id: singleThreat?.threat_id,
      }),
    );
    setEditMode(null);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleToggleIsPublic = () => {
    setEditedIsPublic(!editedIsPublic);
  };

  const handleApplyNotes = () => {
    const items = selectedNoteIds.map((noteId: any) => ({
      domain_id: singleThreat?.protected_asset_id,
      threat_id: singleThreat?.threat_id,
      note_id: noteId,
    }));
    dispatch(
      createThreatNotesAction({
        items,
      }),
    );
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <NoteLabelWrapper>
        <Typography variant="h6" sx={{ color: 'black' }}>
          Notes & Comments
        </Typography>
      </NoteLabelWrapper>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 12 }}>
        {getNotesValues?.data?.map((item: any, index: number) => (
          <Grid item xs={6} key={index}>
            <Typography variant="body1" sx={{ my: 1, color: 'black' }}>
              {item.name}
            </Typography>
            {item?.note_values?.map((item: any, index: number) => (
              <Tooltip title={item.description} arrow key={index}>
                <FormControlLabel
                  sx={FormControlLabelStyle}
                  control={
                    <Checkbox
                      sx={CheckboxStyle}
                      size="small"
                      checked={selectedNoteIds.includes(item.id)}
                      onChange={() => handleNotesChange(item.id)}
                    />
                  }
                  label={item.name}
                />
              </Tooltip>
            ))}
          </Grid>
        ))}
        <ButtonsStyle>
          <Button
            variant="contained"
            startIcon={<CheckCircleIcon />}
            sx={{ background: '#0fd6c2', mr: 1, minWidth: 90 }}
            disabled={selectedNoteIds.length === 0 || createNotes.loading}
            onClick={handleApplyNotes}>
            {createNotes.loading ? <Loader size={18} /> : 'Apply'}
          </Button>
          <Button variant="contained" sx={{ background: '#291b67' }}>
            Cancel
          </Button>
        </ButtonsStyle>
        {getCommentsList?.length === 0 ? (
          <Typography variant="subtitle1" sx={{ color: 'black', m: 2 }}>
            No Comments Found
          </Typography>
        ) : (
          getCommentsList?.map((item: any, index: number) => {
            const createdDate = new Date(item?.created_at).toLocaleString();
            return (
              <CommentsWrapper key={index}>
                <EditModeWrapper>
                  {editMode === index ? (
                    <>
                      <Input
                        size="small"
                        value={editedComment}
                        onChange={handleChangeEditedComment}
                        sx={{ width: '100%' }}
                      />
                      <Switch
                        {...label}
                        defaultChecked
                        onChange={handleToggleIsPublic}
                      />
                      <UpdateButton
                        variant="contained"
                        onClick={() => handleUpdateComment(item.id)}>
                        Update
                      </UpdateButton>
                    </>
                  ) : (
                    <CommentsText>
                      {item.comments}
                      <IconButton
                        onClick={() => handleToggleEditMode(index)}
                        sx={{ p: 0, ml: 1 }}>
                        <img
                          src={EditCommentsIcon}
                          alt="editIcon"
                          style={EditCommentsIconStyle}
                        />
                      </IconButton>
                      {item.is_public && <PrivateLabel>Private</PrivateLabel>}
                    </CommentsText>
                  )}
                </EditModeWrapper>
                <CreatedByLabel>
                  <Typography sx={{ fontSize: 14 }}>
                    by {item?.created_by} on
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 14 }}
                    color="text.secondary">
                    {createdDate}
                  </Typography>
                </CreatedByLabel>
                <hr style={LinesStyle} />
              </CommentsWrapper>
            );
          })
        )}
        <Box sx={{ mt: 1.5, display: 'flex', width: '100%' }}>
          <Input
            type="text"
            size="small"
            label="Add a comment"
            value={addThreatComments}
            onChange={handleChangeComments}
            sx={{ width: '100%' }}
          />
        </Box>
        <ButtonsStyle>
          <GradientButton
            variant="contained"
            sx={{ mr: 1 }}
            onClick={handleAddComments}>
            Add comment
          </GradientButton>
          <Button variant="contained" sx={{ background: '#291b67' }}>
            Cancel
          </Button>
        </ButtonsStyle>
      </Grid>
    </>
  );
};

export default NotesComments;
