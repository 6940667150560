import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';

export const TotalCard = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  ...theme.typography.body2,
  padding: '30px 20px',
  border: '1px solid #e2e2e2',
  textAlign: 'left',
  color: 'black',
  display: 'flex',
  borderRadius: 14,
  justifyContent: 'space-between',
  boxShadow: 'none',
}));

export const CustomTab = styled(Tab)({
  color: 'black',
  paddingLeft: 0,
  textTransform: 'capitalize',
  fontWeight: 600,
  gap: '50px',
});

export const ThreatsTotal = styled(Box)({
  fontWeight: 'bold',
  marginTop: '4px',
});

export const TotalNumber = {
  color: '#868686',
  fontSize: '12px',
};

export const TotalText = {
  fontWeight: 'bold',
  mt: 2,
};

export const InputContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 3,
};

export const ThreatCardContainer = styled(Box)({
  flexGrow: 1,
  marginTop: '16px',
});

export const ThreatCardWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  marginTop: '50px',
});

export const CommonNotesWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'end',
});
