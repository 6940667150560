import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { SummaryTable } from './Style/SingleThreat.style';
import CloseRedIcon from '../../assets/Images/CloseRedIcon.png';
import MaliciousIcon from '../../assets/Images/MaliciousIcon.png';
import BenignIcon from '../../assets/Images/BenignIcon.svg';
import SuspiciousIcon from '../../assets/Images/SuspiciousIcon.svg';
import NoClassificatioIcon from '../../assets/Images/NoClassificatioIcon.svg';
import { ThreatTypes } from '../../helpers/types';

const IconStyle = { height: '24px', marginLeft: '6px' };

const SummaryDetail = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const { takedownList } = useSelector((state: any) => state.takedown);

  const finishedDescription = takedownList?.data?.items?.map(
    (item: any) => item.finished_description,
  );

  const statusObject = finishedDescription?.find(
    (item: any) =>
      item?.status === 'completed' || item?.status === 'failure_prevention',
  );

  const messageTakedown = () => {
    if (statusObject?.status === 'completed') {
      return 'Success - Domain has been deleted.';
    } else if (statusObject?.status === 'failure_prevention') {
      return 'Ongoing - Active Defense measures have been initiated.';
    } else {
      return 'Started - message';
    }
  };

  const getNetworkLabel = () => {
    const score = singleThreat?.score?.value ?? 0;
    let label, color, icon;

    if (score >= 0.8) {
      label = 'Malicious';
      color = 'red';
      icon = MaliciousIcon;
    } else if (score >= 0.7) {
      label = 'Suspicious';
      color = '#ce7d6a';
      icon = SuspiciousIcon;
    } else if (score >= 0.5) {
      label = 'Unknown';
      color = 'blue';
    } else {
      label = 'Benign';
      color = '#3fbfb6';
      icon = BenignIcon;
    }
    return { label, color, icon };
  };

  const networkLabel = getNetworkLabel();
  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Summary
      </Typography>
      <SummaryTable>
        {singleThreat?.identification_status?.value === 'confirmed' && (
          <Typography variant="subtitle1">
            Takedown:&nbsp;
            {messageTakedown()}
          </Typography>
        )}
        <Typography variant="subtitle1">
          PreCrime Network:&nbsp;
          {singleThreat?.identification_status?.value === 'confirmed' ? (
            <span style={{ color: networkLabel.color }}>
              {networkLabel.label}
              <img src={networkLabel.icon} alt="" style={IconStyle} />
            </span>
          ) : (
            <span style={{ color: 'gray' }}>No Classification</span>
          )}
        </Typography>
        <Typography variant="subtitle1" sx={{ lineHeight: 1 }}>
          Virus Total: <b> 3 partners</b> have flagged this domain&nbsp;
          <img src={CloseRedIcon} alt="" style={IconStyle} />
        </Typography>
        <hr style={{ margin: '12px 0' }} />
        <Box>
          <Typography variant="h6">Live Information</Typography>
          <Typography variant="subtitle1">
            Google Safe Browsing:&nbsp;
            {singleThreat?.identification_status?.value === 'confirmed' ? (
              <span style={{ color: networkLabel.color }}>
                {networkLabel.label}
                <img src={networkLabel.icon} alt="" style={IconStyle} />
              </span>
            ) : (
              <span style={{ color: 'gray' }}>
                No Classification&nbsp;
                <img src={NoClassificatioIcon} alt="" style={IconStyle} />
              </span>
            )}
          </Typography>
          <Typography variant="subtitle1" sx={{ lineHeight: 1 }}>
            Current DNS A Record: 104.22.9.151
          </Typography>
        </Box>
      </SummaryTable>
    </>
  );
};

export default SummaryDetail;
