import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_COMPANY_BEGIN,
  GET_SERVICE_BEGIN,
  GET_STATUS_BEGIN,
  GET_THREAT_BEGIN,
  GET_SSLCERTIFICATE_BEGIN,
  GET_DNSRECORD_BEGIN,
  GET_WHOIS_BEGIN,
  GET_TAKEDOWNLIST_BEGIN,
  CHANGE_STATUSLABEL_BEGIN,
  CREATE_THREAT_BEGIN,
  GET_HUBSPORTID_BEGIN,
  CREATE_TAKEDOWN_BEGIN,
  COMPLETE_TAKEDOWN_BEGIN,
  GET_DOMAINS_LIST_BEGIN,
  GET_HISTORY_STATUSES_BEGIN,
  GET_SUBDOMAINS_BEGIN,
  GET_COMPANYLIST_BEGIN,
  ADD_COMPANY_BEGIN,
  GET_COMPANYDETAIL_BEGIN,
  GET_PROTECTED_DOMAINS_BEGIN,
  ADD_PROTECTED_DOMAINS_BEGIN,
  ADD_CHILD_COMPANY_BEGIN,
  GET_NOTES_ID_BEGIN,
  GET_NOTES_VALUES_BEGIN,
  GET_THREATCOMMENTS_BEGIN,
  CREATE_THREATCOMMENTS_BEGIN,
  CREATE_THREATNOTES_BEGIN,
  DELETE_THREATNOTE_BEGIN,
  UPDATE_THREATCOMMENTS_BEGIN,
  UPDATE_COMPANY_DETAILS_BEGIN,
  UPDATE_COMPANY_INDUSTRY_BEGIN,
  GET_USER_DETAILS_BEGIN,
  GET_USER_ROLE_BEGIN,
  ADD_USER_SAVE_BEGIN,
  GET_KEYWORDS_BEGIN,
  UPDATE_COMPANY_SERVICE_BEGIN,
  DELETE_THREATCOMMENTS_BEGIN,
  DELETE_COMPANY_BEGIN,
  DOMAIN_UNPROTECT_BEGIN,
  CHANGE_STATUS_DOMAIN_BEGIN,
  GET_DOMAIN_KEYWORDS_BEGIN,
  ADD_DOMAIN_KEYWORDS_BEGIN,
  UNSET_PARENT_COMPANY_BEGIN,
  ADDINFORMATION_GENERIC_BEGIN,
  GET_THREAT_DETAIL_BEGIN,
  GET_TAKEDOWN_THREAT_DETAIL_BEGIN,
  GET_STATUS_TAKEDOWN_BEGIN,
} from './constant';
import {
  addChildCompanySaga,
  addCompanySaga,
  addProtectedDomainsSaga,
  deleteCompanySaga,
  getCompanyDetailSaga,
  getCompanyListSaga,
  getProtectedDomainsSaga,
  getCompanySaga,
  getKeywordsSaga,
  getServiceSaga,
  updateCompanyDetailsSaga,
  updateCompanyIndustrySaga,
  updateCompanyServiceSaga,
  domainUnprotectSaga,
  changeStatusDomainSaga,
  getDomainKeywordsSaga,
  addDomainKeywordsSaga,
  unsetParentCompanySaga,
  addInformationGenericSaga,
} from './company/saga';
import {
  changeStatusLabel,
  createThreatSaga,
  getDomainsListSaga,
  getStatusSaga,
  getThreatDetailSaga,
  getThreatSaga,
} from './threats/saga';
import {
  getSSLCertificateSaga,
  getDNSRecordSaga,
  getWhoisSaga,
  getHistoryStatuesSaga,
  getSubDomainsSaga,
} from './singleThreat/saga';
import {
  createThreatCommentsSaga,
  createThreatNotesSaga,
  deleteThreatNoteSaga,
  getThreatCommentsSaga,
  getNotesValuesSaga,
  updateThreatCommentsSaga,
  getNoteIdSaga,
  deleteThreatCommentsSaga,
} from './noteComments/saga';
import {
  completeTakedownSaga,
  createTakedownSaga,
  getHubSportIdSaga,
  getTakedownDetailSaga,
  getTakeDownListSaga,
  getTakedownStatusSaga,
} from './takedown/saga';
import {
  addUserSaveSaga,
  getUserDetailsSaga,
  getUserRoleSaga,
} from './user/saga';

export function* rootSaga() {
  yield all([
    takeLatest(GET_SERVICE_BEGIN, getServiceSaga),
    takeLatest(GET_COMPANY_BEGIN, getCompanySaga),
    takeLatest(GET_STATUS_BEGIN, getStatusSaga),
    takeLatest(GET_THREAT_BEGIN, getThreatSaga),
    takeLatest(GET_THREAT_DETAIL_BEGIN, getThreatDetailSaga),
    takeLatest(GET_DNSRECORD_BEGIN, getDNSRecordSaga),
    takeLatest(GET_SSLCERTIFICATE_BEGIN, getSSLCertificateSaga),
    takeLatest(GET_WHOIS_BEGIN, getWhoisSaga),
    takeLatest(GET_SUBDOMAINS_BEGIN, getSubDomainsSaga),
    takeLatest(GET_TAKEDOWNLIST_BEGIN, getTakeDownListSaga),
    takeLatest(GET_TAKEDOWN_THREAT_DETAIL_BEGIN, getTakedownDetailSaga),
    takeLatest(GET_STATUS_TAKEDOWN_BEGIN, getTakedownStatusSaga),
    takeEvery(CHANGE_STATUSLABEL_BEGIN, changeStatusLabel),
    takeLatest(CREATE_THREAT_BEGIN, createThreatSaga),
    takeLatest(GET_HUBSPORTID_BEGIN, getHubSportIdSaga),
    takeLatest(CREATE_TAKEDOWN_BEGIN, createTakedownSaga),
    takeLatest(COMPLETE_TAKEDOWN_BEGIN, completeTakedownSaga),
    takeLatest(GET_DOMAINS_LIST_BEGIN, getDomainsListSaga),
    takeLatest(GET_HISTORY_STATUSES_BEGIN, getHistoryStatuesSaga),
    takeLatest(GET_COMPANYLIST_BEGIN, getCompanyListSaga),
    takeLatest(DELETE_COMPANY_BEGIN, deleteCompanySaga),
    takeLatest(ADD_COMPANY_BEGIN, addCompanySaga),
    takeLatest(ADD_CHILD_COMPANY_BEGIN, addChildCompanySaga),
    takeLatest(UNSET_PARENT_COMPANY_BEGIN, unsetParentCompanySaga),
    takeLatest(GET_COMPANYDETAIL_BEGIN, getCompanyDetailSaga),
    takeLatest(GET_PROTECTED_DOMAINS_BEGIN, getProtectedDomainsSaga),
    takeLatest(ADD_PROTECTED_DOMAINS_BEGIN, addProtectedDomainsSaga),
    takeLatest(DOMAIN_UNPROTECT_BEGIN, domainUnprotectSaga),
    takeLatest(ADDINFORMATION_GENERIC_BEGIN, addInformationGenericSaga),
    takeLatest(CHANGE_STATUS_DOMAIN_BEGIN, changeStatusDomainSaga),
    takeLatest(GET_DOMAIN_KEYWORDS_BEGIN, getDomainKeywordsSaga),
    takeLatest(ADD_DOMAIN_KEYWORDS_BEGIN, addDomainKeywordsSaga),
    takeLatest(GET_NOTES_VALUES_BEGIN, getNotesValuesSaga),
    takeLatest(GET_NOTES_ID_BEGIN, getNoteIdSaga),
    takeLatest(CREATE_THREATNOTES_BEGIN, createThreatNotesSaga),
    takeLatest(DELETE_THREATNOTE_BEGIN, deleteThreatNoteSaga),
    takeLatest(GET_THREATCOMMENTS_BEGIN, getThreatCommentsSaga),
    takeLatest(CREATE_THREATCOMMENTS_BEGIN, createThreatCommentsSaga),
    takeLatest(UPDATE_THREATCOMMENTS_BEGIN, updateThreatCommentsSaga),
    takeLatest(UPDATE_COMPANY_DETAILS_BEGIN, updateCompanyDetailsSaga),
    takeLatest(UPDATE_COMPANY_INDUSTRY_BEGIN, updateCompanyIndustrySaga),
    takeLatest(UPDATE_COMPANY_SERVICE_BEGIN, updateCompanyServiceSaga),
    takeLatest(GET_USER_DETAILS_BEGIN, getUserDetailsSaga),
    takeLatest(GET_USER_ROLE_BEGIN, getUserRoleSaga),
    takeLatest(ADD_USER_SAVE_BEGIN, addUserSaveSaga),
    takeLatest(GET_KEYWORDS_BEGIN, getKeywordsSaga),
    takeLatest(DELETE_THREATCOMMENTS_BEGIN, deleteThreatCommentsSaga),
  ]);
}
