import { DeleteCommentsType } from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getNotesValuesApi = async () => {
  const config = {
    method: 'GET',
    url: `v2/evidences/notes/values`,
  };
  const res = await apiClient(config);
  return res;
};

export const getNoteIdApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${payload.domain_id}/threats/${payload.threat_id}/notes?page=${payload.page}&size=${payload.size}`,
  };
  const res = await apiClient(config);
  return res;
};

export const createThreatNotesApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `v2/evidences/notes`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteThreatNoteApi = async (payload: any) => {
  const config = {
    method: 'DELETE',
    url: `v2/evidences/domains/${payload.domain_id}/threats/${payload.threat_id}/notes/${payload.note_id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getThreatCommentsApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/evidences/domains/${payload.domain_id}/threats/${payload.threat_id}/comments`,
  };
  const res = await apiClient(config);
  return res;
};

export const createThreatCommentsApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `v2/evidences/comments`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const updateThreatCommentsApi = async (data: any) => {
  const config = {
    method: 'PUT',
    url: `v2/evidences/comments/${data.comment_id}`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteThreatCommentsApi = async (payload: DeleteCommentsType) => {
  const config = {
    method: 'DELETE',
    url: `v2/evidences/comments/${payload.comment_id}`,
  };
  const res = await apiClient(config);
  return res;
};
