import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  Box,
  Modal,
  Grid,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Loader } from '../../components';
import {
  NotesHeader,
  NotesWrapper,
  ViewModalStyle,
  ViewNotesWrapper,
} from './Style/ViewThreatNotesModal.style';
import {
  createThreatNotesAction,
  getNotesValuesAction,
} from '../../store/noteComments/action';
import {
  ButtonsStyle,
  CheckboxStyle,
  FormControlLabelStyle,
} from '../ThreadsDetails/Style/SingleThreat.style';

const AddCommonNotesModal = ({ open, handleClose, selectedThreats }: any) => {
  const [selectedNoteIds, setSelectedNoteIds] = useState<any>([]);

  const dispatch = useDispatch();
  const { getNotesValues, createNotes } = useSelector(
    (state: any) => state.noteComments,
  );

  useEffect(() => {
    if (open) {
      dispatch(getNotesValuesAction({}));
    }
  }, [open]);

  const handleApplyNotes = () => {
    const items = selectedThreats
      .map((threat: any) =>
        selectedNoteIds.map((noteId: any) => ({
          domain_id: threat.domainId,
          threat_id: threat.threatId,
          note_id: noteId,
        })),
      )
      .flat();
    dispatch(
      createThreatNotesAction({
        items,
      }),
    );
    setSelectedNoteIds([]);
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={ViewModalStyle} style={{ width: '60%' }}>
          <NotesHeader>
            <DialogTitle sx={{ m: 0, p: 0 }}>Notes</DialogTitle>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                label="Cancel"
              />
            </Box>
          </NotesHeader>
          <ViewNotesWrapper>
            <Grid container spacing={1}>
              <NotesWrapper>
                <Typography sx={{ mb: 1, mt: 1 }}>
                  Threat Notes List:
                </Typography>
                {getNotesValues.loading ? (
                  <Box sx={{ m: 12 }}>
                    <Loader size={25} />
                  </Box>
                ) : (
                  <Grid container spacing={{ xs: 1.5 }}>
                    {getNotesValues?.data?.map((item: any, index: number) => (
                      <Grid item xs={6} key={index}>
                        <Typography
                          variant="body1"
                          sx={{ my: 1, color: 'black' }}>
                          {item.name}
                        </Typography>
                        {item?.note_values?.map((item: any, index: number) => (
                          <Tooltip title={item.description} arrow key={index}>
                            <FormControlLabel
                              sx={FormControlLabelStyle}
                              control={
                                <Checkbox
                                  sx={CheckboxStyle}
                                  size="small"
                                  checked={selectedNoteIds.includes(item.id)}
                                  onChange={() => handleNotesChange(item.id)}
                                />
                              }
                              label={item.name}
                            />
                          </Tooltip>
                        ))}
                      </Grid>
                    ))}
                    <ButtonsStyle>
                      <Button
                        variant="contained"
                        startIcon={<CheckCircleIcon />}
                        sx={{ background: '#0fd6c2', mr: 1, minWidth: 90 }}
                        disabled={
                          selectedNoteIds.length === 0 || createNotes.loading
                        }
                        onClick={handleApplyNotes}>
                        {createNotes.loading ? <Loader size={18} /> : 'Apply'}
                      </Button>
                    </ButtonsStyle>
                  </Grid>
                )}
              </NotesWrapper>
            </Grid>
          </ViewNotesWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default AddCommonNotesModal;
