import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Magnify from 'mdi-material-ui/Magnify';
import TextField from '@mui/material/TextField';
import Button from '../Button/Button';
import { SearchBarProps } from '../../helpers/types';

const SearchBar = ({
  size,
  handleChange,
  handleSearch,
  searchValue,
}: SearchBarProps) => {
  return (
    <>
      <TextField
        size={size}
        onChange={handleChange}
        value={searchValue}
        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 8 }, mr: 2 }}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Magnify fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <Button variant="outlined" label="Search" onClick={handleSearch} />
    </>
  );
};

export default SearchBar;
