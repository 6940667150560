import React, { useCallback, useState } from 'react';
import {
  styled,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components';
import CompleteTakedownModal from '../ThreadsDetails/CompleteTakedownModal';
import { TakedownTableType } from '../../helpers/types';
import { completeTakedownAction } from '../../store/takedown/action';
import { LoaderWrapper, NoDataMessage } from '../../utils';

const CustomTableCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
}));

const TakedownTable = ({
  takeDownCurrentPage,
  startDateFilterValues,
  endDateFilterValues,
}: TakedownTableType) => {
  const [openCompleteTakedown, setOpenCompleteTakedown] = useState(false);
  const [takedownMessage, setTakedownMessage] = useState<string>('');
  const [getTakedownId, setGetTakedownId] = useState<string>('');
  const [selectedStatusTakedown, setSelectedStatusTakedown] =
    useState<string>('');

  const dispatch = useDispatch();
  const { takedownList, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const takeDownItems = takedownList?.data?.items;

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const findStatusNameById = (id: number) => {
    const status = getStatustakeDown?.data?.find(
      (status: any) => status?.id === id,
    );
    return status ? status.name : 'Unknown';
  };

  const handleCloseCompleteTakedown = () => {
    setOpenCompleteTakedown(false);
  };

  const handleChangeStatusTakedown = (e: any) => {
    setSelectedStatusTakedown(e.target.value);
  };

  const statusTakedownData = useCallback(
    getStatustakeDown?.data?.map((item: any) => ({
      label: item.name,
      value: item.name,
    })),
    [getStatustakeDown?.data],
  );

  const handleCompleteTakedown = () => {
    dispatch(
      completeTakedownAction({
        id: getTakedownId,
        description: takedownMessage,
        status_name: selectedStatusTakedown,
      }),
    );
    setTakedownMessage('');
    setOpenCompleteTakedown(false);
  };

  const handleOpenCompleteTakedown = (takeDownId: string) => {
    setGetTakedownId(takeDownId);
    setOpenCompleteTakedown(true);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ border: '1px solid lightgray' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>URL</CustomTableCell>
              <CustomTableCell align="left">Target</CustomTableCell>
              <CustomTableCell align="left">Description</CustomTableCell>
              <CustomTableCell align="left">User Id</CustomTableCell>
              <CustomTableCell align="left">Requested</CustomTableCell>
              <CustomTableCell align="left">Finished</CustomTableCell>
              <CustomTableCell align="left">Status</CustomTableCell>
              <CustomTableCell align="left">Action</CustomTableCell>
            </TableRow>
          </TableHead>
          {takedownList?.loading ? (
            <LoaderWrapper size={25} />
          ) : takeDownItems?.length === 0 ? (
            <NoDataMessage message="No Takedown Data" />
          ) : (
            <TableBody>
              {takeDownItems?.map((takeDown: any) => (
                <TableRow
                  key={takeDown?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="takeDown">
                    <Tooltip title={takeDown?.url}>
                      <span>
                        {takeDown?.url && takeDown.url.length > 25
                          ? `${takeDown.url.slice(0, 25)}...`
                          : takeDown?.url}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{takeDown?.target}</TableCell>
                  <TableCell align="left">
                    <Tooltip title={takeDown?.description || '-'}>
                      <span>
                        {takeDown?.description &&
                        takeDown.description.length > 30
                          ? `${takeDown.description.slice(0, 30)}...`
                          : takeDown?.description || '-'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{takeDown?.user_id}</TableCell>
                  <TableCell align="left">
                    {formatDate(takeDown?.created)}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(takeDown?.updated_at)}
                  </TableCell>
                  <TableCell align="left">
                    {findStatusNameById(takeDown?.status_id)}
                  </TableCell>
                  <TableCell align="left">
                    {takeDown?.status_id !== 8 && (
                      <Button
                        variant="contained"
                        onClick={() => handleOpenCompleteTakedown(takeDown.id)}
                        sx={{ background: '#34a6ed' }}>
                        Complete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <CompleteTakedownModal
        handleClose={handleCloseCompleteTakedown}
        openCompleteTakedown={openCompleteTakedown}
        handleCompleteTakedown={handleCompleteTakedown}
        takedownMessage={takedownMessage}
        setTakedownMessage={setTakedownMessage}
        handleChangeStatusTakedown={handleChangeStatusTakedown}
        statusTakedownData={statusTakedownData}
      />
    </>
  );
};

export default TakedownTable;
