import React, { useState } from 'react';
import {
  Dialog,
  styled,
  DialogTitle,
  IconButton,
  FormControlLabel,
  DialogContent,
  DialogActions,
  Checkbox,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { GenerateReportModalType } from '../../helpers/types';
import { GradientButton } from '../../components';
import PreviewPdfModal from './PreviewPdfModal/PreviewPdfModal';
import {
  getDNSRecordAction,
  getSSLCertificateAction,
  getWhoisAction,
} from '../../store/singleThreat/action';

const GenerateReportDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    height: 300,
    width: 500,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    justifyContent: 'center',
    display: 'flex',
  },
}));

const CheckboxStyle = {
  '&.Mui-checked': {
    color: '#0fd6c2',
  },
};

const GenerateReportModal = ({
  openThreatReport,
  handleClose,
  singleThreat,
  imageData,
}: GenerateReportModalType) => {
  const [openPreviewPdfModal, setOpenPreviewPdfModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const handlePreviewPdf = () => {
    setOpenPreviewPdfModal(true);
    if (singleThreat && singleThreat.threat_name) {
      dispatch(getDNSRecordAction(singleThreat.threat_name));
      dispatch(getSSLCertificateAction(singleThreat.threat_name));
      dispatch(getWhoisAction(singleThreat.threat_name));
    }
  };

  const handleClosePreviewPdfModal = () => {
    setOpenPreviewPdfModal(false);
  };

  return (
    <>
      <GenerateReportDialog onClose={handleClose} open={openThreatReport}>
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }}>
          Export PDF
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControlLabel
            control={<Checkbox sx={CheckboxStyle} />}
            label="Export with public notes"
          />
          <FormControlLabel
            control={<Checkbox sx={CheckboxStyle} />}
            label="Export with private notes"
          />
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={handlePreviewPdf}>
            Preview & Generate PDF
          </GradientButton>
        </DialogActions>
      </GenerateReportDialog>
      <PreviewPdfModal
        openPreviewPdfModal={openPreviewPdfModal}
        handleClose={handleClosePreviewPdfModal}
        singleThreat={singleThreat}
        imageData={imageData}
      />
    </>
  );
};

export default GenerateReportModal;
