import {
  COMPLETE_TAKEDOWN_BEGIN,
  COMPLETE_TAKEDOWN_FAILURE,
  COMPLETE_TAKEDOWN_SUCCESS,
  CREATE_TAKEDOWN_BEGIN,
  CREATE_TAKEDOWN_FAILURE,
  CREATE_TAKEDOWN_SUCCESS,
  GET_HUBSPORTID_BEGIN,
  GET_HUBSPORTID_FAILURE,
  GET_HUBSPORTID_SUCCESS,
  GET_STATUS_TAKEDOWN_BEGIN,
  GET_STATUS_TAKEDOWN_FAILURE,
  GET_STATUS_TAKEDOWN_SUCCESS,
  GET_TAKEDOWN_THREAT_DETAIL_BEGIN,
  GET_TAKEDOWN_THREAT_DETAIL_FAILURE,
  GET_TAKEDOWN_THREAT_DETAIL_SUCCESS,
  GET_TAKEDOWNLIST_BEGIN,
  GET_TAKEDOWNLIST_FAILURE,
  GET_TAKEDOWNLIST_SUCCESS,
} from '../constant';

const initialState = {
  takedownList: {
    loading: false,
    data: [],
    error: null,
  },
  takeDownDetails: {
    loading: false,
    data: [],
    error: null,
  },
  getStatustakeDown: {
    loading: false,
    data: [],
    error: null,
  },
  createTakedown: {
    loading: false,
    data: [],
    error: null,
  },
  completeTakedown: {
    loading: false,
    data: [],
    error: null,
  },
  hubSportId: {
    loading: false,
    data: [],
    error: null,
  },
};

export const takedownReducer = (
  state = initialState,
  action: { type: string; payload: string },
) => {
  switch (action.type) {
    case GET_TAKEDOWNLIST_BEGIN:
      return {
        ...state,
        takedownList: {
          ...state.takedownList,
          loading: true,
        },
      };
    case GET_TAKEDOWNLIST_SUCCESS:
      return {
        ...state,
        takedownList: {
          ...state.takedownList,
          loading: false,
          data: action.payload,
        },
      };
    case GET_TAKEDOWNLIST_FAILURE:
      return {
        ...state,
        takedownList: {
          ...state.takedownList,
          loading: false,
          error: action.payload,
        },
      };
    case GET_TAKEDOWN_THREAT_DETAIL_BEGIN:
      return {
        ...state,
        takeDownDetails: {
          ...state.takeDownDetails,
          loading: true,
        },
      };
    case GET_TAKEDOWN_THREAT_DETAIL_SUCCESS:
      return {
        ...state,
        takeDownDetails: {
          ...state.takeDownDetails,
          loading: false,
          data: action.payload,
        },
      };
    case GET_TAKEDOWN_THREAT_DETAIL_FAILURE:
      return {
        ...state,
        takeDownDetails: {
          ...state.takeDownDetails,
          loading: false,
          error: action.payload,
        },
      };
    case GET_STATUS_TAKEDOWN_BEGIN:
      return {
        ...state,
        getStatustakeDown: {
          ...state.getStatustakeDown,
          loading: true,
        },
      };
    case GET_STATUS_TAKEDOWN_SUCCESS:
      return {
        ...state,
        getStatustakeDown: {
          ...state.getStatustakeDown,
          loading: false,
          data: action.payload,
        },
      };
    case GET_STATUS_TAKEDOWN_FAILURE:
      return {
        ...state,
        getStatustakeDown: {
          ...state.getStatustakeDown,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_TAKEDOWN_BEGIN:
      return {
        ...state,
        createTakedown: {
          ...state.createTakedown,
          loading: true,
        },
      };
    case CREATE_TAKEDOWN_SUCCESS:
      return {
        ...state,
        createTakedown: {
          ...state.createTakedown,
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_TAKEDOWN_FAILURE:
      return {
        ...state,
        createTakedown: {
          ...state.createTakedown,
          loading: false,
          error: action.payload,
        },
      };
    case COMPLETE_TAKEDOWN_BEGIN:
      return {
        ...state,
        completeTakedown: {
          ...state.completeTakedown,
          loading: true,
        },
      };
    case COMPLETE_TAKEDOWN_SUCCESS:
      return {
        ...state,
        completeTakedown: {
          ...state.completeTakedown,
          loading: false,
          data: action.payload,
        },
      };
    case COMPLETE_TAKEDOWN_FAILURE:
      return {
        ...state,
        completeTakedown: {
          ...state.completeTakedown,
          loading: false,
          error: action.payload,
        },
      };
    case GET_HUBSPORTID_BEGIN:
      return {
        ...state,
        hubSportId: {
          ...state.hubSportId,
          loading: true,
        },
      };
    case GET_HUBSPORTID_SUCCESS:
      return {
        ...state,
        hubSportId: {
          ...state.hubSportId,
          loading: false,
          data: action.payload,
        },
      };
    case GET_HUBSPORTID_FAILURE:
      return {
        ...state,
        hubSportId: {
          ...state.hubSportId,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
