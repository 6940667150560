/* eslint-disable no-case-declarations */
import { StatusesType, ThreatTypes } from './../../helpers/types';
import { ThreatReducerType } from '../../helpers/types';
import {
  CHANGE_STATUSLABEL_BEGIN,
  CHANGE_STATUSLABEL_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
  CREATE_THREAT_BEGIN,
  CREATE_THREAT_FAILURE,
  CREATE_THREAT_SUCCESS,
  GET_DOMAINS_LIST_BEGIN,
  GET_DOMAINS_LIST_FAILURE,
  GET_DOMAINS_LIST_SUCCESS,
  GET_STATUS_BEGIN,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_THREAT_BEGIN,
  GET_THREAT_DETAIL_BEGIN,
  GET_THREAT_DETAIL_FAILURE,
  GET_THREAT_DETAIL_SUCCESS,
  GET_THREAT_FAILURE,
  GET_THREAT_SUCCESS,
  RESET_THREAT_LIST,
  SET_STATUS_PERSIST_VALUES,
  TOGGLE_INFINITE_SCROLL,
} from '../constant';
import { statusList } from '../../utils';

const initialState = {
  statusChangeIds: [],
  searchTerm: '',
  isInfiniteScroll: false,
  loading: false,
  threatList: {
    Threats: [],
    Statuses: [],
  },
  threatDetail: {},
  createThreat: {
    loading: false,
    data: [],
    error: null,
  },
  domainList: {
    loading: false,
    data: [],
    error: null,
  },
  getStatus: {
    loading: false,
    data: [],
    error: null,
  },
  statusPersistValues: [],
};

export const threatReducer = (state = initialState, action: any) => {
  let threatUniqueId: string;
  if (action?.payload?.companyId) {
    const { companyId, threatId, domainId } = action.payload;
    threatUniqueId = `${companyId}${domainId}${threatId}`;
  }
  switch (action.type) {
    case RESET_THREAT_LIST:
      return {
        ...state,
        threatList: { Threats: [] },
      };
    case GET_THREAT_BEGIN:
      return {
        ...state,
        loading: true,
        searchTerm: action.payload?.threat_name,
      };

    case GET_THREAT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        threatDetail: action.payload
      };

    case GET_THREAT_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_THREAT_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_THREAT_SUCCESS:
      if (state.isInfiniteScroll) {
        return {
          ...state,
          loading: false,
          threatList: {
            ...state.threatList,
            Threats: [...state.threatList.Threats, ...action.payload.Threats],
            Total: action.payload.Total,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          threatList: {
            ...action.payload,
          },
        };
      }
    case GET_THREAT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case TOGGLE_INFINITE_SCROLL:
      return {
        ...state,
        isInfiniteScroll: action.payload,
      };
    case CHANGE_STATUSLABEL_BEGIN:
      return {
        ...state,
        threatList: {
          ...state.threatList,
          Threats: state?.threatList?.Threats?.map((threat: ThreatTypes) =>
            `${threat.company_id}${threat.protected_asset_id}${threat.threat_id}` ===
            threatUniqueId
              ? { ...threat, isLoading: true, error: null }
              : threat,
          ),
        },
      };
    case CHANGE_STATUSLABEL_SUCCESS:
      const status: StatusesType | undefined = statusList?.find(
        (status: StatusesType) => status.Name === action?.payload?.status,
      );

      const newThreats = state?.threatList?.Threats?.map(
        (threat: ThreatTypes) => {
          const shouldUpdate =
            `${threat.company_id}${threat.protected_asset_id}${threat.threat_id}` ===
            threatUniqueId;

          if (shouldUpdate) {
            return {
              ...threat,
              StatusId: status?.Id,
              isLoading: false,
              error: null,
            };
          }
          return threat;
        },
      );
      const updatedThreats = newThreats?.filter(
        threat =>
          `${threat.company_id}${threat.protected_asset_id}${threat.threat_id}` !==
          threatUniqueId,
      );

      if (action.payload.singleThreatStatus) {
        return {
          ...state,
          threatList: {
            ...state.threatList,
            Threats: newThreats,
          },
        };
      } else {
        return {
          ...state,
          threatList: {
            ...state.threatList,
            Threats: updatedThreats,
          },
        };
      }
    case CHANGE_STATUSLABEL_FAILURE:
      return {
        ...state,
        threatList: {
          ...state.threatList,
          Threats: state.threatList?.Threats?.map((threat: ThreatTypes) =>
            `${threat.company_id}${threat.protected_asset_id}${threat.threat_id}` ===
            threatUniqueId
              ? { ...threat, isLoading: false, error: action.error }
              : threat,
          ),
        },
      };
    case CREATE_THREAT_BEGIN:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: true,
        },
      };
    case CREATE_THREAT_SUCCESS:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_THREAT_FAILURE:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: false,
          error: action.payload,
        },
      };
    case GET_DOMAINS_LIST_BEGIN:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: true,
        },
      };
    case GET_DOMAINS_LIST_SUCCESS:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: false,
          data: action.payload,
        },
      };
    case GET_DOMAINS_LIST_FAILURE:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: false,
          error: action.payload,
        },
      };
    case SET_STATUS_PERSIST_VALUES:
      return {
        ...state,
        statusPersistValues: action.payload,
      };
    case GET_STATUS_BEGIN:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: true,
        },
      };
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: false,
          data: action.payload,
        },
      };
    case GET_STATUS_FAILURE:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
