import { HistoryStatuesType } from '../../helpers/types';
import {
  GET_DNSRECORD_BEGIN,
  GET_HISTORY_STATUSES_BEGIN,
  GET_SSLCERTIFICATE_BEGIN,
  GET_SUBDOMAINS_BEGIN,
  GET_WHOIS_BEGIN,
} from '../constant';

export const getDNSRecordAction = (payload: string | null) => ({
  type: GET_DNSRECORD_BEGIN,
  payload,
});

export const getSSLCertificateAction = (payload: string | null) => ({
  type: GET_SSLCERTIFICATE_BEGIN,
  payload,
});

export const getWhoisAction = (payload: string | null) => ({
  type: GET_WHOIS_BEGIN,
  payload,
});

export const getHistoryStatusesAction = (payload: HistoryStatuesType) => ({
  type: GET_HISTORY_STATUSES_BEGIN,
  payload,
});

export const getSubDomainsAction = (payload: number | null) => ({
  type: GET_SUBDOMAINS_BEGIN,
  payload,
});
