import React from 'react';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CustomTableCell } from '../CompaniesList/Style/CompaniesList.style';
import { falsePositive } from '../../utils';

const FalsePositiveTable = () => {
  return (
    <>
      <TableContainer component={Paper} sx={{ border: '1px solid lightgray' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>
                <Checkbox
                  size="small"
                  // checked={ }
                />
              </CustomTableCell>
              <CustomTableCell align="left">DOMAINS NAME</CustomTableCell>
              <CustomTableCell align="left">CREATED BY</CustomTableCell>
              <CustomTableCell align="left">CREATED DATE</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {falsePositive?.map((item: any) => {
              return (
                <TableRow key={item?.domain}>
                  <TableCell>
                    <Checkbox
                      size="small"
                      // checked={isItemSelected}
                      // onClick={event => handleClickDomain(event, item)}
                    />
                  </TableCell>
                  <TableCell>{item.domain}</TableCell>
                  <TableCell align="left">{item.created_by}</TableCell>
                  <TableCell align="left">{item.created_date}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FalsePositiveTable;
