import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  CustomListItemButton,
  CustomListItemIcon,
  Drawer,
  DrawerHeader,
} from './Style/Sidebar.style';
import { SidebarProps } from '../../helpers/types';
import { menuItems } from '../../utils';

const Sidebar = ({ handleDrawerClose, open }: SidebarProps) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mr: 3 }}>
          Dashboard
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.to;
          return (
            <Link
              to={item.to}
              key={item.id}
              style={{ textDecoration: 'none', color: 'black' }}>
              <ListItem disablePadding sx={{ paddingRight: '16px' }}>
                <CustomListItemButton isActive={isActive} open={open}>
                  <CustomListItemIcon isActive={isActive}>
                    {item.icon}
                  </CustomListItemIcon>
                  <ListItemText
                    primary={item.menuName}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </CustomListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
