import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  Box,
  Modal,
  Grid,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, GradientButton, Input, Loader } from '../../components';
import { ThreatNotesModalType } from '../../helpers/types';
import CreateThreatNotesModal from './CreateThreatNotesModal';
import ThreatCommentsListCard from './ThreatCommentsListCard';
import {
  CommentsWrapper,
  NotesHeader,
  NotesWrapper,
  ViewModalStyle,
  ViewNotesWrapper,
} from './Style/ViewThreatNotesModal.style';
import {
  createThreatCommentsAction,
  createThreatNotesAction,
  getNotesIdAction,
  getNotesValuesAction,
  getThreatCommentsAction,
} from '../../store/noteComments/action';
import {
  ButtonsStyle,
  CheckboxStyle,
  FormControlLabelStyle,
} from '../ThreadsDetails/Style/SingleThreat.style';

const ViewThreatNotesModal = ({
  openThreatNoteModal,
  handleCloseThreatNote,
  items,
}: ThreatNotesModalType) => {
  const [openCreateThreatNotes, setOpenCreateThreatNotes] =
    useState<boolean>(false);
  const [addThreatComments, setAddThreatComments] = useState<string>('');
  const [selectedNoteIds, setSelectedNoteIds] = useState<any>([]);

  const dispatch = useDispatch();
  const { getComments, getNotesValues, createNotes, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );
  const getCommentsList = getComments?.data?.items;
  const { threat_id, protected_asset_id } = items;

  useEffect(() => {
    if (openThreatNoteModal) {
      dispatch(getNotesValuesAction({}));
      dispatch(
        getNotesIdAction({
          domain_id: items?.protected_asset_id,
          threat_id: items?.threat_id,
          page: 1,
          size: 50,
        }),
      );
      dispatch(
        getThreatCommentsAction({
          domain_id: items?.protected_asset_id,
          threat_id: items?.threat_id,
        }),
      );
    }
  }, [openThreatNoteModal]);

  useEffect(() => {
    if (getNoteId?.data?.items) {
      const noteIds = getNoteId.data.items.map((item: any) => item.note_id);
      setSelectedNoteIds(noteIds);
    }
  }, [getNoteId]);

  const handleCreateComments = () => {
    dispatch(
      createThreatCommentsAction({
        items: [
          {
            comments: addThreatComments,
            domain_id: items?.protected_asset_id,
            threat_id: items?.threat_id,
            is_public: true,
          },
        ],
      }),
    );
    setAddThreatComments('');
  };

  const handleChangeComments = (e: any) => {
    setAddThreatComments(e.target.value);
  };

  const handleApplyNotes = () => {
    const items = selectedNoteIds.map((noteId: any) => ({
      domain_id: protected_asset_id,
      threat_id: threat_id,
      note_id: noteId,
    }));
    dispatch(
      createThreatNotesAction({
        items,
      }),
    );
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <Modal open={openThreatNoteModal} onClose={handleCloseThreatNote}>
        <Box sx={ViewModalStyle}>
          <NotesHeader>
            <DialogTitle sx={{ m: 0, p: 0 }}>Notes</DialogTitle>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {/* <GradientButton
                label="+ Create Notes"
                onClick={handleOpenCreateNote}
                sx={{ fontWeight: 600 }}
              /> */}
              <Button
                onClick={handleCloseThreatNote}
                variant="outlined"
                color="secondary"
                label="Cancel"
              />
            </Box>
          </NotesHeader>
          <ViewNotesWrapper>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography sx={{ mb: 1 }}>Threat Notes List:</Typography>
                <NotesWrapper>
                  {getNotesValues.loading ? (
                    <Box sx={{ m: 12 }}>
                      <Loader size={25} />
                    </Box>
                  ) : (
                    <Grid container spacing={{ xs: 1.5 }}>
                      {getNotesValues?.data?.map((item: any, index: number) => (
                        <Grid item xs={6} key={index}>
                          <Typography
                            variant="body1"
                            sx={{ my: 1, color: 'black' }}>
                            {item.name}
                          </Typography>
                          {item?.note_values?.map(
                            (item: any, index: number) => (
                              <Tooltip
                                title={item.description}
                                arrow
                                key={index}>
                                <FormControlLabel
                                  sx={FormControlLabelStyle}
                                  control={
                                    <Checkbox
                                      sx={CheckboxStyle}
                                      size="small"
                                      checked={selectedNoteIds.includes(
                                        item.id,
                                      )}
                                      onChange={() =>
                                        handleNotesChange(item.id)
                                      }
                                    />
                                  }
                                  label={item.name}
                                />
                              </Tooltip>
                            ),
                          )}
                        </Grid>
                      ))}
                      <ButtonsStyle>
                        <Button
                          variant="contained"
                          startIcon={<CheckCircleIcon />}
                          sx={{ background: '#0fd6c2', mr: 1, minWidth: 90 }}
                          disabled={
                            selectedNoteIds.length === 0 || createNotes.loading
                          }
                          onClick={handleApplyNotes}>
                          {createNotes.loading ? <Loader size={18} /> : 'Apply'}
                        </Button>
                        <Button variant="outlined">Cancel</Button>
                      </ButtonsStyle>
                    </Grid>
                  )}
                </NotesWrapper>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ mb: 1 }}>Threat Comments:</Typography>
                <CommentsWrapper>
                  {getComments.loading ? (
                    <Box sx={{ m: 12 }}>
                      <Loader size={25} />
                    </Box>
                  ) : getCommentsList?.length === 0 ? (
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'black', m: 10 }}>
                      No Comments
                    </Typography>
                  ) : (
                    getCommentsList?.map((comments: any, index: number) => (
                      <ThreatCommentsListCard key={index} comments={comments} />
                    ))
                  )}
                </CommentsWrapper>
                <Input
                  placeholder="comments"
                  size="small"
                  multiline
                  rows={3}
                  value={addThreatComments}
                  sx={{ width: '100%' }}
                  onChange={handleChangeComments}
                />
                <GradientButton
                  label="Send"
                  sx={{ marginTop: '10px' }}
                  onClick={handleCreateComments}
                />
              </Grid>
            </Grid>
          </ViewNotesWrapper>
        </Box>
      </Modal>
      {/* <CreateThreatNotesModal
        handleCloseCreateThreatNotes={() => setOpenCreateThreatNotes(false)}
        openCreateThreatNotes={openCreateThreatNotes}
        items={items}
      /> */}
    </>
  );
};

export default ViewThreatNotesModal;
